import { useQuery } from '@tanstack/react-query'
import camelcaseKeys from 'camelcase-keys'

import { ListCampaignOnboardingJobsResponseDto } from '~shared/types/campaign-onboarding.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useCampaignOnboardingJobs(campaignId: string) {
  const getJobs = async () => {
    const { data } =
      await ApiService.get<ListCampaignOnboardingJobsResponseDto>(
        `/campaign-onboarding/${campaignId}/jobs`,
      )
    return data ? camelcaseKeys(data) : data
  }

  const { data: jobs, isLoading: isLoadingJobs } = useQuery({
    ...queryKeys.campaignOnboardingJobs.list,
    queryFn: () => getJobs(),
    refetchInterval: 3000, // 3s
  })

  return { jobs, isLoadingJobs }
}
