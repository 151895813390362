import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Singapore')

export function formatAsShortDateString(date: Date | string) {
  return moment(date).format('D/M/YYYY')
}

export function formatAsLongDateString(date: Date | string) {
  return moment(date).format('D MMM YYYY')
}

export function formatAsDateTimeString(date: Date | string) {
  return moment(date).format('D MMM YYYY hh:mm A')
}

export function formatAsTimeFromNow(date: Date | string) {
  return moment(date).fromNow()
}
