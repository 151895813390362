import { HStack, Spacer, Text } from '@chakra-ui/react'

interface TableStatisticsRowProps {
  rowName: string
  allocated: number
  distributed: number
  isWalkIn: boolean
}

export const TableStatisticsRow = ({
  rowName,
  allocated,
  distributed,
  isWalkIn,
}: TableStatisticsRowProps) => {
  return (
    <HStack justifyContent="space-between" alignSelf="stretch">
      <Text textStyle="body-2" width="19rem">
        {rowName}
      </Text>
      <Spacer />
      {!isWalkIn && (
        <Text textStyle="body-2" width="96px" textAlign="right">
          {Intl.NumberFormat('en').format(allocated)}
        </Text>
      )}
      <Text textStyle="body-2" width="96px" textAlign="right">
        {Intl.NumberFormat('en').format(distributed)}
      </Text>
    </HStack>
  )
}
