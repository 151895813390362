import _ from 'lodash'

import { ApiError } from './api'

export function isClientError(error: unknown): error is ApiError {
  if (!(error instanceof ApiError)) {
    return false
  }
  const errorCode = error.httpStatusCode
  return !_.isNil(errorCode) && errorCode >= 400 && errorCode < 500
}
