import { createQueryKeyStore } from '@lukemorales/query-key-factory'

/**
 * Generate query key constants to be used by react-query
 */
export const queryKeys = createQueryKeyStore({
  currentUser: null,
  selectedCampaign: null,
  selectedLocation: null,
  campaigns: {
    list: null,
    detail: (campaignId: string) => ({
      queryKey: [campaignId],
      contextQueries: {
        admins: {
          queryKey: null,
        },
        distributors: {
          queryKey: null,
        },
        allocations: {
          queryKey: null,
        },
        allocationsCount: {
          queryKey: null,
        },
        distributionCount: {
          queryKey: null,
        },
        locationCount: {
          queryKey: null,
        },
        locations: {
          queryKey: null,
          contextQueries: {
            detail: (locationId: string) => ({
              queryKey: [locationId],
              contextQueries: {
                stats: {
                  queryKey: null,
                },
              },
            }),
          },
        },
        statistics: {
          queryKey: null,
        },
      },
    }),
  },
  campaignOnboardingJobs: {
    list: null,
  },
  distributions: {
    list: null,
  },
})

export const RETRIES_MAX = 3
