const baseStyle = {
  step: {
    '--stepper-indicator-size': '20px',
  },
  indicator: {
    color: 'white',
    '&[data-status=active], &[data-status=complete]': {
      borderColor: 'brand.primary.500',
      backgroundColor: 'brand.primary.500',
    },
    '&[data-status=incomplete]': {
      backgroundColor: 'interaction.support.disabled-content',
      borderColor: 'interaction.support.disabled-content',
    },
  },
  separator: {
    '&[data-status=active], &[data-status=complete]': {
      backgroundColor: 'brand.primary.500',
    },
    '&[data-status=incomplete]': {
      backgroundColor: 'interaction.support.disabled-content',
    },
  },
  icon: {
    fontSize: '20px',
  },
  number: {
    font: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
}

export const stepperTheme = {
  baseStyle,
}
