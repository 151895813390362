import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface NegativeTextProps {
  children?: ReactNode
}

export const NegativeText = ({ children }: NegativeTextProps) => {
  return (
    <Text as="i" color="#919AAB">
      {children}
    </Text>
  )
}
