import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { AdminCampaignLayout } from '~/layouts/AdminCampaignLayout'

import { ManageCampaignAdmin } from '../components/ManageCampaignAdmin'
import { RemoveCampaign } from '../components/RemoveCampaign'
import { ToggleCampaignStatus } from '../components/ToggleCampaignStatus'

import { useCampaign } from '~hooks/useCampaign'

export const SettingsCard = (): JSX.Element => {
  return <></>
}

export const SettingsPage = (): JSX.Element => {
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)

  return (
    <AdminCampaignLayout
      campaignId={campaignId}
      campaignName={campaign?.name}
      campaignStatus={campaign?.status}
      sidebarActiveIndex={3}
    >
      <VStack
        alignItems="stretch"
        spacing={8}
        py={12}
        px={6}
        bg="base.canvas.alt"
        h="$100vh"
        // take up the remaining width of it's parent HStack
        w="100%"
        // override the base margin
        ml="0px !important"
      >
        <HStack alignItems="baseline" justifyContent="space-between">
          <Text textStyle="h3">Settings</Text>
        </HStack>
        <VStack alignItems="flex-start" maxW="2xl" gap={4}>
          {/* Campaign settings components go here */}
          <Box bg="white" p={6} borderRadius="4px" w="43rem">
            <ToggleCampaignStatus campaignId={campaignId} />
          </Box>
          <Box
            bg="white"
            p={6}
            borderRadius="4px"
            w="43rem"
            // there is a underlying margin-top of 0.5rem for some reason
            mt="0px !important"
          >
            <ManageCampaignAdmin campaignId={campaignId} />
          </Box>
          <Box
            bg="white"
            p={6}
            borderRadius="4px"
            w="43rem"
            mt="0px !important"
          >
            <RemoveCampaign campaignId={campaignId} />
          </Box>
        </VStack>
      </VStack>
    </AdminCampaignLayout>
  )
}
