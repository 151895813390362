import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Button, useToast } from '@opengovsg/design-system-react'
import { IsNotEmpty } from 'class-validator'
import { useForm } from 'react-hook-form'

import { useAddLocation } from '~hooks/useAddLocation'
import { isClientError } from '~lib/helpers/error'

type AddLocationModalProps = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  campaignId: string
}

class AddLocationFormState {
  @IsNotEmpty({
    message: 'Location name must not be empty',
  })
  locationName: string
}

export const AddLocationModal = ({
  isOpen,
  onClose,
  campaignId,
}: AddLocationModalProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<AddLocationFormState>({
    resolver: classValidatorResolver(AddLocationFormState),
  })
  const { addLocation, isAddingLocation } = useAddLocation(campaignId)

  const showErrorToast = useToast({ status: 'error' })
  const showSuccessToast = useToast({ status: 'success' })

  const handleAddLocation = async (locationName: string) => {
    try {
      await addLocation({ name: locationName, metadata: {} })
      showSuccessToast({ title: 'Location successfully added' })
      closeModal()
    } catch (error) {
      if (isClientError(error)) {
        setError('locationName', {
          message: error.message,
        })
      } else {
        showErrorToast({ title: 'Unable to add location' })
        closeModal()
      }
    }
  }

  const onFormSubmit = async (data: AddLocationFormState) => {
    await handleAddLocation(data.locationName)
  }

  const closeModal = () => {
    reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a new location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!!errors.locationName}>
              <Text textStyle="body-1" mb="24px">
                Your location name does not have to correspond to a real
                address. You can name it according to what works best for your
                campaign.
              </Text>
              <FormLabel>Location Name</FormLabel>
              <Input
                type="text"
                id="locationName"
                placeholder="Bedok North CC"
                {...control.register('locationName')}
              />
              <FormErrorMessage>
                {errors.locationName?.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <FormControl isInvalid={!!errors.root}>
              <FormErrorMessage>{errors.root?.message}</FormErrorMessage>
            </FormControl>
            <Button type="submit" isLoading={isSubmitting || isAddingLocation}>
              Add location
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
