import { useQuery } from '@tanstack/react-query'

import { GetAllocationCountResponseDto } from '~shared/types/allocation.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export const useAllocationCount = (campaignId: string) => {
  const getAllocationCount = async () => {
    const response = await ApiService.get<GetAllocationCountResponseDto>(
      `/campaigns/${campaignId}/allocations/count`,
    )

    return response.data.count
  }

  const { data: allocationCount } = useQuery({
    ...queryKeys.campaigns.detail(campaignId)._ctx.allocationsCount,
    queryFn: getAllocationCount,
    // API call is lightweight and we want to ensure data is consistent
    // between users viewing the allocation count within the same campaign
    // hence we set a shorter refetchInterval
    refetchInterval: 5000, // 5 seconds
  })

  return {
    allocationCount,
  }
}
