import { Box, Divider, HStack, VStack } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { CampaignStatus } from '~shared/constants/campaign'

import { Navbar } from '~components/Navbar/Navbar'
import { SideTabList } from '~components/SideMenu/SideTabList'

interface AdminCampaignLayoutProps {
  children?: ReactNode
  campaignId: string
  campaignName?: string
  campaignStatus?: CampaignStatus
  sidebarActiveIndex?: number
}

export const AdminCampaignLayout = ({
  children,
  campaignId,
  campaignName,
  campaignStatus,
  sidebarActiveIndex,
}: AdminCampaignLayoutProps) => {
  return (
    <VStack pt={4} overflow="hidden">
      <Box w="full" pr={8} pl={8}>
        <Navbar
          campaignName={campaignName}
          campaignStatus={campaignStatus}
          campaignId={campaignId}
        />
      </Box>
      <Divider orientation="horizontal" />
      <HStack
        alignItems="flex-start"
        // override margin top to be 0px
        mt="0px !important"
        w="100%"
      >
        <HStack alignItems="flex-start">
          <Box mt={8} pl={4} w="max-content">
            <SideTabList
              campaignId={campaignId}
              activeIndex={sidebarActiveIndex}
            />
          </Box>
          <Divider orientation="vertical" />
        </HStack>
        <Box flex={1} minW={0}>
          {children}
        </Box>
      </HStack>
    </VStack>
  )
}
