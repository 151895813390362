import { useQuery } from '@tanstack/react-query'

import { GetLocationCountResponseDto } from '~shared/types/location.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export const useLocationCount = (campaignId: string) => {
  const getLocationCount = async () => {
    const response = await ApiService.get<GetLocationCountResponseDto>(
      `/campaigns/${campaignId}/locations/count`,
    )

    return response.data.count
  }

  const { data: locationCount, isLoading: isLocationCountLoading } = useQuery({
    ...queryKeys.campaigns.detail(campaignId)._ctx.locationCount,
    queryFn: getLocationCount,
    // API call is lightweight and we want to ensure data is consistent
    // between users viewing the location count within the same campaign
    // hence we set a shorter refetchInterval
    refetchInterval: 5000, // 5 seconds
  })

  return {
    locationCount,
    isLocationCountLoading,
  }
}
