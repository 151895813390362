import { useQuery } from '@tanstack/react-query'
import camelcaseKeys from 'camelcase-keys'

import {
  ListCampaignUsersQueryParamsDto,
  ListCampaignUsersResponseDto,
} from '~shared/types/campaign-user.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useCampaignAdmins({ campaignId }: { campaignId: string }) {
  const listCampaignAdmins = async () => {
    const queryParams: ListCampaignUsersQueryParamsDto = {
      type: 'admin',
    }
    const response = await ApiService.get<ListCampaignUsersResponseDto>(
      `/campaigns/${campaignId}/campaign-users`,
      {
        params: queryParams,
      },
    )

    return response.data
      ? camelcaseKeys(response.data, { deep: false })
      : response.data
  }

  const { data: campaignAdmins, isLoading: isCampaignAdminsLoading } = useQuery(
    {
      ...queryKeys.campaigns.detail(campaignId)._ctx.admins,
      queryFn: listCampaignAdmins,
      refetchInterval: 180000, // 3mins
    },
  )

  return {
    campaignAdmins,
    isCampaignAdminsLoading,
  }
}
