import { CampaignOnboardingBulkUploadModal } from '~components/CampaignOnboarding/CampaignOnboardingBulkUploadModal'
import { useDownloadTemplateCsv } from '~features/campaign/hooks/useDownloadTemplateCsv'
import { useUploadCampaignOnboardingCsv } from '~features/campaign/hooks/useUploadCampaignOnboardingCsv'

export type BulkAddLocationModalProps = {
  campaignId: string
  isOpen: boolean
  showDataOverrideWarning: boolean
  onOpen: () => void
  onClose: () => void
}

const MODAL_TITLE = 'Upload multiple locations'
const MODAL_DESCRIPTION = 'You can upload your locations with a CSV file.'
const MODAL_INSTRUCTIONS_HEADER = 'Fill in CSV template with your locations'
const MODAL_INSTRUCTIONS_CONTENT =
  'Location names do not need to correspond to actual addresses. You can choose the names for your campaign as you see fit.'
const MODAL_DATA_OVERRIDE_WARNING_DESCRIPTION =
  'Uploading a CSV file will override your existing locations.'

export const BulkUploadLocationModal = ({
  campaignId,
  isOpen,
  showDataOverrideWarning,
  onClose,
}: BulkAddLocationModalProps) => {
  const { downloadTemplateCsv } = useDownloadTemplateCsv()
  const { uploadCampaignOnboardingCsv, isUploadingCampaignOnboardingCsv } =
    useUploadCampaignOnboardingCsv()

  return (
    <CampaignOnboardingBulkUploadModal
      title={MODAL_TITLE}
      description={MODAL_DESCRIPTION}
      instructionsHeader={MODAL_INSTRUCTIONS_HEADER}
      instructionsContent={MODAL_INSTRUCTIONS_CONTENT}
      showDataOverrideWarning={showDataOverrideWarning}
      dataOverrideWarningAlertDescription={
        MODAL_DATA_OVERRIDE_WARNING_DESCRIPTION
      }
      isOpen={isOpen}
      onClose={onClose}
      onUpload={(file: File) =>
        uploadCampaignOnboardingCsv({
          file,
          campaignId,
          type: 'upload_locations',
        })
      }
      isUploading={isUploadingCampaignOnboardingCsv}
      downloadTemplateCsv={() =>
        downloadTemplateCsv({ campaignId, type: 'upload_locations' })
      }
    />
  )
}
