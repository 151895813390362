import { Button, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { EmptyDistributorsCardSvg } from './EmptyDistributorsCardSvg'

import { EmptyStateCard } from '~components/EmptyStateCard/EmptyStateCard'

// content to be placed as children of EmptyPageCard
// for when there is no Distributors data
export const EmptyDistributorsCard = ({
  handleOnUploadMultipleDistributorsButtonClick,
}: {
  handleOnUploadMultipleDistributorsButtonClick: () => void
}): JSX.Element => {
  return (
    <EmptyStateCard>
      <HStack pl={12} pr={35} h="100%" justifyContent="space-evenly">
        {/* left content */}
        <VStack maxW="25rem" alignItems="flex-start" spacing={8}>
          <Text textStyle="body-1">
            This is where you manage your distributors. Add the people who will
            distribute items to recipients on the ground here.
          </Text>
          <Button
            px={4}
            py={2}
            onClick={handleOnUploadMultipleDistributorsButtonClick}
          >
            <Text textStyle="subhead-2">Upload Multiple Distributors</Text>
          </Button>
        </VStack>

        {/* Divider */}
        <VStack h="20rem" position="relative" py={8}>
          <Divider orientation="vertical" borderColor="brand.primary.300" />
          <Text color="brand.primary.300" fontSize="0.625rem" fontWeight="500">
            OR
          </Text>
          <Divider orientation="vertical" borderColor="brand.primary.300" />
        </VStack>

        {/* right content */}
        <Flex direction="column" alignItems="center">
          <EmptyDistributorsCardSvg display={{ base: 'none', lg: 'flex' }} />
        </Flex>
      </HStack>
    </EmptyStateCard>
  )
}
