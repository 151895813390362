import { Box, Text, VStack } from '@chakra-ui/react'
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'

import { DistributorView } from '~/types/distributor'

import { PlainBadge } from '~components/Badge/PlainBadge'
import DataTable, {
  ACTIONS_COLUMN_ID,
  ControlledPaginationNavigation,
} from '~components/DataTable'
import { NegativeText } from '~components/NegativeText/NegativeText'

const columnHelper = createColumnHelper<DistributorView>()
const columnsToDisplay = new Set([
  'identifier',
  'name',
  'locations',
  'createdAt',
])
const specialColumns = new Set(['locations'])
const headersToCustomise: { [key: string]: string } = {
  identifier: 'email / mobile',
  createdAt: 'joined',
}
const filterableColumns = new Set(['identifier'])

interface DistributorsTableProps {
  handleDeleteRow: (row: Row<DistributorView>) => void
  isDeletingDistributor: boolean
  data: DistributorView[]
}

export const DistributorsTable = ({
  handleDeleteRow,
  isDeletingDistributor,
  data,
}: DistributorsTableProps) => {
  const columns = useMemo(
    () => [
      ...Object.keys(data?.[0] ?? [])
        .filter((key) => columnsToDisplay.has(key))
        .filter((key) => !specialColumns.has(key))
        .map((key) => {
          return columnHelper.accessor(key as keyof DistributorView, {
            cell: (info) => {
              const value = info.getValue()
              if (!value) {
                return <NegativeText>-</NegativeText>
              }
              return value
            },
            header: () => {
              return (
                <Text textStyle="caption-1">
                  {(key in headersToCustomise
                    ? headersToCustomise[key]
                    : key
                  ).toUpperCase()}
                </Text>
              )
            },
            enableColumnFilter: filterableColumns.has(key),
          })
        }),
      columnHelper.accessor('locations', {
        cell: (info) => {
          const locations = info.getValue()
          if (!locations.length) {
            return <NegativeText>Not assigned</NegativeText>
          }
          return locations.map((location) => (
            <PlainBadge label={location} mr={1} my={1} />
          ))
        },
        header: () => (
          <Text textStyle="caption-1">{'locations'.toUpperCase()}</Text>
        ),
        enableColumnFilter: filterableColumns.has('locations'),
      }),
      columnHelper.display({
        id: ACTIONS_COLUMN_ID,
        cell: () => null,
        header: () => (
          <Text textStyle="caption-1">{'actions'.toUpperCase()}</Text>
        ),
      }),
    ],
    [data],
  )

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),

    // pagination
    manualPagination: false,
    getPaginationRowModel: getPaginationRowModel(),

    // filtering
    manualFiltering: false,
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: true,

    // sorting
    manualSorting: true,
    enableSorting: false,
    enableSortingRemoval: false,

    state: {
      sorting: [],
      columnOrder: ['identifier', 'name', 'locations'],
      columnVisibility: {
        id: false,
        campaignId: false,
        userId: false,
        updatedAt: false,
      },
    },
  })

  return (
    <Box>
      {data.length > 0 ? (
        <VStack spacing={8} alignItems="stretch">
          <DataTable
            instance={table}
            enableRowEditOptions={true}
            handleDeleteRow={handleDeleteRow}
            isDeletingRow={isDeletingDistributor}
          />
          <ControlledPaginationNavigation table={table} />
        </VStack>
      ) : (
        <Text>There are no distributors yet.</Text>
      )}
    </Box>
  )
}
