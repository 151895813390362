import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  HStack,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'

import { TableStatisticsRow } from './TableStatisticsRow'

import { useCampaignStatistics } from '~hooks/useCampaignStatistics'

interface ItemStatisticsProps {
  campaignId: string
  isWalkIn: boolean
}

export const ItemStatistics = ({
  campaignId,
  isWalkIn,
}: ItemStatisticsProps) => {
  const { campaignStatistics, isFetching } = useCampaignStatistics(campaignId)

  const itemStats = campaignStatistics?.distribution_stats_by_item ?? []

  const totalItemDistributionCount = itemStats.reduce(
    (sum, current) => sum + current.distribution_count,
    0,
  )
  const totalItemDistributionCountFormatted = Intl.NumberFormat('en').format(
    totalItemDistributionCount,
  )

  const itemStatsData = itemStats.map((itemStatsRow) => {
    return {
      itemName: itemStatsRow.product_name,
      allocated: itemStatsRow.allocation_count ?? 0,
      distributed: itemStatsRow.distribution_count,
    }
  })

  return (
    <Box bg="white" borderRadius="4px" width="50%">
      <VStack alignItems="stretch" px={8} pt={8} mb={6}>
        <HStack>
          <Text textStyle="h6" color="base.content.strong">
            Total Items Distributions
          </Text>
          <Tooltip
            label={
              <Text textStyle="body-2">
                See the total number of items distributed and a breakdown by
                item types. This includes appeals that have been made.
              </Text>
            }
            placement="top"
            hasArrow
          >
            <InfoOutlineIcon />
          </Tooltip>
          <Spacer />
          <Skeleton
            isLoaded={!isFetching}
            startColor="grey.100"
            endColor="grey.50"
          >
            <Text textStyle="h6" color="base.content.strong">
              {totalItemDistributionCountFormatted}
            </Text>
          </Skeleton>
        </HStack>
        <Divider my={2} />
        <HStack>
          <Text textStyle="subhead-2" width="18rem">
            Items
          </Text>
          <Spacer />
          {!isWalkIn && (
            <Text textStyle="subhead-2" width="96px" textAlign="right">
              Allocated
            </Text>
          )}
          <Text textStyle="subhead-2" width="96px" textAlign="right">
            Distributed
          </Text>
        </HStack>
        <Skeleton
          isLoaded={!isFetching}
          startColor="grey.100"
          endColor="grey.50"
        >
          <VStack minH="18rem" maxH="18rem" overflowY="auto">
            {itemStatsData.map((data) => {
              return (
                <TableStatisticsRow
                  rowName={data.itemName}
                  allocated={data.allocated}
                  distributed={data.distributed}
                  // Item name within a campaign is
                  // unique based on user input constraint
                  key={data.itemName}
                  isWalkIn={isWalkIn}
                />
              )
            })}
          </VStack>
        </Skeleton>
      </VStack>
    </Box>
  )
}
