import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { ManageCampaignAdminModal } from './ManageCampaignAdminModal'

// TODO: can extract into a generic toggle setting component later
export const ManageCampaignAdmin = ({ campaignId }: { campaignId: string }) => {
  // methods to handle opening and closing of
  // ManageCampaignAdminModal
  const {
    isOpen: isManageCampaignAdminModalOpen,
    onOpen: onManageCampaignAdminModalOpen,
    onClose: onManageCampaignAdminModalClose,
  } = useDisclosure()

  return (
    <>
      <ManageCampaignAdminModal
        campaignId={campaignId}
        isOpen={isManageCampaignAdminModalOpen}
        onClose={onManageCampaignAdminModalClose}
      />
      <HStack w="full" alignItems="flex-start">
        <VStack alignItems="flex-start" mr="auto">
          <Text textStyle="subhead-1">Manage Collaborators</Text>
          <Text textStyle="body-2">
            View, add or remove collaborators from your campaign.{' '}
          </Text>
        </VStack>
        <Button
          variant="outline"
          onClick={onManageCampaignAdminModalOpen}
          type="submit"
          size="xs"
        >
          Manage
        </Button>
      </HStack>
    </>
  )
}
