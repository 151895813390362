import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const EmptyStateCard = ({
  children,
}: {
  children?: ReactNode
}): JSX.Element => {
  return (
    // background colour is followed from figma design to achieve
    // gradient effect
    <Box
      bg="linear-gradient(92deg, #F5F5FC 1.39%, #D6D6FF 100.32%)"
      borderRadius="8px"
    >
      {children}
    </Box>
  )
}
