import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { EmptyActiveCampaignDistributionsCardSvg } from './EmptyActiveCampaignDistributionsCardSvg'
import { EmptyInactiveCampaignDistributionsCardSvg } from './EmptyInactiveCampaignDistributionsCardSvg'

import { EmptyStateCard } from '~components/EmptyStateCard/EmptyStateCard'

// content to be placed as children of EmptyPageCard
// for when there is no Distribution data
export const EmptyDistributionsCard = ({
  isCampaignActive,
  campaignId,
}: {
  isCampaignActive: boolean
  campaignId?: string
}): JSX.Element => {
  const navigate = useNavigate()

  const handleOnSettingsButtonClick = () => {
    if (campaignId) {
      navigate(`/${campaignId}/settings`)
    }
  }

  const header = !isCampaignActive
    ? 'You are almost ready to launch your campaign!'
    : 'There are no distributions for this campaign yet.'

  const description = !isCampaignActive
    ? 'Once you have completed your campaign setup, you can go to Settings to activate your campaign.'
    : 'Once your distributors have started distributing to your recipients, the details of these distributions will be displayed here.'

  return (
    <EmptyStateCard>
      <HStack justifyContent="space-evenly" pl={12} pr={35}>
        {/* left content */}
        <VStack maxW="26rem" alignItems="flex-start" spacing={6}>
          <Text textStyle="h6">{header}</Text>
          <Text textStyle="body-1">{description}</Text>
          {!isCampaignActive && (
            <Box pt={2}>
              <Button px={4} py={2} onClick={handleOnSettingsButtonClick}>
                <Text textStyle="subhead-2">Go to Settings</Text>
              </Button>
            </Box>
          )}
        </VStack>

        {/* right content */}
        {isCampaignActive ? (
          <EmptyActiveCampaignDistributionsCardSvg maxW="100%" aria-hidden />
        ) : (
          <EmptyInactiveCampaignDistributionsCardSvg maxW="100%" aria-hidden />
        )}
      </HStack>
    </EmptyStateCard>
  )
}
