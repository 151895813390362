import { useQuery } from '@tanstack/react-query'
import camelcaseKeys from 'camelcase-keys'

import {
  ListCampaignUsersQueryParamsDto,
  ListCampaignUsersResponseDto,
} from '~shared/types/campaign-user.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'
import { reprintDateTime } from '~utils/date'

export const useDistributors = (campaignId: string) => {
  const listDistributors = async () => {
    const queryParams: ListCampaignUsersQueryParamsDto = {
      type: 'distributor',
    }
    const { data } = await ApiService.get<ListCampaignUsersResponseDto>(
      `/campaigns/${campaignId}/campaign-users`,
      {
        params: queryParams,
      },
    )

    return camelcaseKeys(data).map((distributor) => ({
      id: distributor.id,
      userId: distributor.user.id,
      email: distributor.user.email,
      mobile: distributor.user.mobile,
      identifier: distributor.user.email || distributor.user.mobile || '',
      name: distributor.user.name,
      locations: distributor.locations.map((location) => location.name),
      createdAt: reprintDateTime(distributor.createdAt),
    }))
  }

  const { data: distributors, isLoading: isDistributorsLoading } = useQuery({
    ...queryKeys.campaigns.detail(campaignId)._ctx.distributors,
    queryFn: listDistributors,
    // API call is lightweight and we want to ensure data is consistent
    // between users viewing the distributors data within the same campaign
    refetchInterval: 5000, // 5 seconds
  })

  return {
    distributors,
    isDistributorsLoading,
  }
}
