import {
  Button,
  HStack,
  Skeleton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { AdminCampaignLayout } from '~/layouts/AdminCampaignLayout'

import { AllocationBreakdownSection } from '../components/AllocationBreakdownSection'
import { CampaignStatisticsCard } from '../components/CampaignStatisticsCard'
import { DistributionBreakdownSection } from '../components/DistributionBreakdownSection'
import { ItemStatistics } from '../components/ItemStatistics'
import { LocationStatistics } from '../components/LocationStatistics'

import { useCampaign } from '~hooks/useCampaign'
import { useCampaignStatistics } from '~hooks/useCampaignStatistics'
import { getCurrentTime } from '~utils/date'

export const StatisticsPage = (): JSX.Element => {
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)
  const [currentDateTime, setCurrentDateTime] = useState<string>(
    getCurrentTime(),
  )
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)

  const { isFetching, refetchCampaignStatistics } =
    useCampaignStatistics(campaignId)

  const campaignIsWalkIn = campaign?.isWalkIn ?? false

  const toast = useToast()

  const onClickUpdateStatistics = async () => {
    setIsLoadingButton(true)
    const updateStatistics = async () => {
      await refetchCampaignStatistics()

      toast({
        status: 'success',
        title: 'Update successful!',
        description: 'Your statistics have been updated',
      })

      // Set all the following states only after
      // the async invalidation of queries is successful
      setIsLoadingButton(false)
      setCurrentDateTime(getCurrentTime())
    }

    await updateStatistics()
  }

  return (
    <AdminCampaignLayout
      campaignId={campaignId}
      campaignName={campaign?.name}
      campaignStatus={campaign?.status}
      sidebarActiveIndex={5}
    >
      <VStack
        alignItems="stretch"
        spacing={8}
        py={12}
        px={6}
        bg="base.canvas.alt"
        minH="$100vh"
        // override the base margin
        ml="0px !important"
      >
        <HStack alignItems="flex-end" justifyContent="space-between">
          <VStack alignItems="flex-start" spacing={1}>
            <Text textStyle="h3">Statistics</Text>
            <Skeleton
              isLoaded={!isFetching}
              startColor="grey.100"
              endColor="grey.50"
            >
              <Text textStyle="subhead-1" textColor="brand.secondary.500">
                As of {currentDateTime}
              </Text>
            </Skeleton>
          </VStack>
          <Spacer />
          <Button onClick={onClickUpdateStatistics} isLoading={isLoadingButton}>
            <Text textStyle="subhead-2">Update Statistics</Text>
          </Button>
        </HStack>
        <CampaignStatisticsCard
          isWalkIn={campaignIsWalkIn}
          campaignId={campaignId}
        />
        {!campaignIsWalkIn && (
          <AllocationBreakdownSection campaignId={campaignId} />
        )}
        <DistributionBreakdownSection
          campaignId={campaignId}
          isWalkInCampaign={campaignIsWalkIn}
        />
        <HStack alignItems="flex-start">
          <LocationStatistics
            campaignId={campaignId}
            isWalkIn={campaignIsWalkIn}
          />
          <ItemStatistics campaignId={campaignId} isWalkIn={campaignIsWalkIn} />
        </HStack>
      </VStack>
    </AdminCampaignLayout>
  )
}
