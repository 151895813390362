import { defineStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

const baseStyle = (styleProps: StyleFunctionProps) => {
  return styleProps
}

const getStyle = (styleProps: StyleFunctionProps) => {
  const colors = getColors(styleProps.colorScheme)
  return {
    bg: colors.bg,
    color: colors.color,
  }
}

const getColors = (colorScheme: string) => {
  switch (colorScheme) {
    case 'success':
      return {
        bg: 'green.100',
        color: 'green.600',
      }
    case 'main':
      return {
        bg: 'cobalt.100',
        color: 'cobalt.600',
      }
    default:
      return {}
  }
}

export const StatusBadge = defineStyleConfig({
  baseStyle: (styleProps) => baseStyle(styleProps),
  variants: {
    subtle: (styleProps) => getStyle(styleProps),
  },
  defaultProps: {
    colorScheme: 'blue',
  },
})
