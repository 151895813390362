/**
 * Do not edit directly
 * Generated on Wed, 26 Jul 2023 10:01:18 GMT
 */

export const spacing = {
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
  '11': '2.75rem',
  '12': '3rem',
  '13': '3.5rem',
  '14': '4rem',
}
