import { DeleteCampaignUserResponseDto } from '~shared/types/campaign-user.dto'

import { ApiService } from '~lib/helpers/api'

export const deleteDistributorById = async (
  campaignId: string,
  campaignUserId: number,
) => {
  const res = await ApiService.delete<DeleteCampaignUserResponseDto>(
    `/campaigns/${campaignId}/campaign-users/${campaignUserId}`,
  )

  return res.data
}
