import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

type ExportLoadingModalProps = {
  isOpen: boolean
  content: React.JSX.Element
  primaryButton: React.JSX.Element
  header: string
  onClose: () => void
}

export const ExportLoadingModal = ({
  isOpen,
  content,
  header,
  primaryButton,
  onClose,
}: ExportLoadingModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody py={2}>{content}</ModalBody>
        <ModalFooter>{primaryButton}</ModalFooter>
      </ModalContent>
    </Modal>
  )
}
