import { chakra } from '@chakra-ui/react'
import { SVGProps } from 'react'

export const EmptyInactiveCampaignDistributionsCardSvg = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      width="386"
      height="268"
      viewBox="0 0 386 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_3081_105838)">
        <mask
          id="mask0_3081_105838"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="36"
          width="386"
          height="245"
        >
          <path d="M386 36H0V281H386V36Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3081_105838)">
          <path
            d="M216.656 72H159.343C145.899 72 135 81.1549 135 92.4478V265.553C135 276.845 145.899 286 159.343 286H216.656C230.1 286 241 276.845 241 265.553V92.4478C241 81.1549 230.1 72 216.656 72Z"
            fill="#CBCBF2"
          />
          <path
            d="M362.114 36H305.883C292.692 36 282 44.3091 282 54.559V268.441C282 278.691 292.692 287 305.883 287H362.114C375.305 287 386 278.691 386 268.441V54.559C386 44.3091 375.305 36 362.114 36Z"
            fill="#CBCBF2"
          />
          <path
            d="M272.798 123.264L225.828 150.795L233.703 189.213L290.611 149.355L272.798 123.264Z"
            fill="#9B9BDE"
          />
          <path
            d="M281.799 152.957C290.705 151.607 296.705 143.6 295.299 135.052C293.893 126.505 285.549 120.747 276.642 122.096C267.736 123.446 261.736 131.454 263.142 140.001C264.548 148.548 272.892 154.306 281.799 152.957Z"
            fill="#3A3A94"
          />
          <path
            d="M305.711 96.8145L303.461 97.6242L304.023 99.0637L306.274 98.254L305.711 96.8145Z"
            fill="#101052"
          />
          <path
            d="M301.573 102.215C301.292 103.205 301.948 104.194 302.979 104.464C304.011 104.734 305.042 104.104 305.323 103.115C305.604 102.125 304.948 101.135 303.917 100.865C302.886 100.596 301.854 101.225 301.573 102.215Z"
            fill="#F5167A"
          />
          <path
            d="M345.643 286.205H262.016L253.203 220.346H336.83L345.643 286.205ZM263.516 284.585H343.768L335.424 221.875H255.078L263.516 284.585Z"
            fill="white"
          />
          <path
            d="M295.852 252.104L295.758 284.583H305.321L295.852 252.104Z"
            fill="#101052"
          />
          <path
            d="M163.622 285.247L256.217 285.779C261.897 285.811 266.528 281.419 266.559 275.975L267.638 102.574C267.67 97.1212 263.094 92.6766 257.421 92.6474L164.828 92.1154C159.146 92.0839 154.515 96.4757 154.485 101.92L153.406 275.313C153.375 280.766 157.95 285.21 163.623 285.239L163.622 285.247Z"
            fill="#2A2A7D"
          />
          <path
            d="M161.762 286.266C156.297 285.382 152.143 280.799 152.172 275.316L153.251 101.913C153.272 98.9658 154.487 96.1957 156.667 94.1298C158.851 92.0559 161.752 90.9223 164.823 90.9437L257.409 91.4745C260.482 91.488 263.367 92.661 265.52 94.754C267.681 96.8492 268.862 99.6338 268.84 102.58L267.762 275.975C267.741 278.921 266.527 281.692 264.346 283.758C262.162 285.832 259.261 286.966 256.19 286.944L163.596 286.411C162.969 286.406 162.35 286.353 161.748 286.255L161.762 286.266ZM258.869 93.9397C258.387 93.8621 257.898 93.8228 257.394 93.8205L164.8 93.2874C162.38 93.2773 160.099 94.1691 158.376 95.7942C156.651 97.4261 155.698 99.6045 155.687 101.927L154.608 275.32C154.577 280.114 158.616 284.036 163.611 284.066L256.206 284.6C258.624 284.61 260.906 283.718 262.629 282.093C264.354 280.46 265.307 278.282 265.318 275.96L266.405 102.567C266.415 100.244 265.486 98.0559 263.793 96.4016C262.445 95.0891 260.734 94.2411 258.878 93.9408L258.869 93.9397Z"
            fill="#2A2A7D"
          />
          <path
            d="M169.521 285.293L256.491 285.783C262.015 285.815 266.518 281.543 266.551 276.243L267.618 102.308C267.649 97.0072 263.198 92.6852 257.676 92.6537L170.704 92.1623C165.181 92.1319 160.678 96.4033 160.645 101.704L159.578 275.639C159.547 280.939 163.998 285.261 169.521 285.293Z"
            fill="white"
          />
          <path
            d="M171.844 142.29C171.844 141.578 172.421 141 173.134 141H254.645C255.358 141 255.936 141.578 255.936 142.29V273.267H171.844V142.29Z"
            fill="white"
          />
          <mask
            id="mask1_3081_105838"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="171"
            y="141"
            width="85"
            height="133"
          >
            <path
              d="M171.844 142.29C171.844 141.578 172.421 141 173.134 141H254.645C255.358 141 255.936 141.578 255.936 142.29V273.267H171.844V142.29Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_3081_105838)">
            <path
              d="M171.844 142.29C171.844 141.578 172.421 141 173.134 141H254.645C255.358 141 255.936 141.578 255.936 142.29V191.756H171.844V142.29Z"
              fill="#F0FAF3"
            />
            <path
              d="M255.936 191.756H171.844V213.908H255.936V191.756Z"
              fill="#F0F3F7"
            />
            <path
              d="M255.936 213.908H171.844V273.267H255.936V213.908Z"
              fill="white"
            />
            <path
              d="M255.076 218.209H172.704C172.229 218.209 171.844 218.594 171.844 219.069V248.319C171.844 248.794 172.229 249.179 172.704 249.179H255.076C255.551 249.179 255.936 248.794 255.936 248.319V219.069C255.936 218.594 255.551 218.209 255.076 218.209Z"
              fill="#F0F3F7"
            />
            <path
              d="M255.076 252.621H172.704C172.229 252.621 171.844 253.006 171.844 253.481V262.084C171.844 262.559 172.229 262.944 172.704 262.944H255.076C255.551 262.944 255.936 262.559 255.936 262.084V253.481C255.936 253.006 255.551 252.621 255.076 252.621Z"
              fill="#4F4FAB"
            />
          </g>
          <path
            d="M225.673 141.001C225.673 135.3 221.051 130.678 215.349 130.678C209.648 130.678 205.026 135.3 205.026 141.001C205.026 146.702 209.648 151.324 215.349 151.324C221.051 151.324 225.673 146.702 225.673 141.001Z"
            stroke="#F0FAF3"
            stroke-width="1.72055"
          />
          <path
            d="M224.705 141C224.705 135.833 220.516 131.645 215.35 131.645C210.183 131.645 205.994 135.833 205.994 141C205.994 146.167 210.183 150.356 215.35 150.356C220.516 150.356 224.705 146.167 224.705 141Z"
            fill="#42AD7B"
          />
          <path
            d="M224.705 141C224.705 135.833 220.516 131.645 215.35 131.645C210.183 131.645 205.994 135.833 205.994 141C205.994 146.167 210.183 150.356 215.35 150.356C220.516 150.356 224.705 146.167 224.705 141Z"
            stroke="#228A60"
            stroke-width="0.215069"
          />
          <path
            d="M211.836 141.501L213.844 143.509L218.862 138.49"
            stroke="#228A60"
            stroke-width="1.72055"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M211.836 141.501L213.844 143.509L218.862 138.49"
            stroke="white"
            stroke-width="1.29041"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M167.708 286.301C162.379 285.44 158.329 280.97 158.36 275.621L159.437 101.671C159.471 95.735 164.538 90.9305 170.722 90.971L257.701 91.4692C263.878 91.5007 268.893 96.3648 268.85 102.298L267.768 276.239C267.732 282.175 262.665 286.979 256.482 286.938L169.51 286.442C168.9 286.439 168.296 286.389 167.71 286.294L167.708 286.301ZM169.524 284.105L256.504 284.602C261.342 284.631 265.3 280.877 265.33 276.234L266.406 102.284C266.436 97.6412 262.516 93.8422 257.686 93.8141L170.714 93.3181C165.876 93.2889 161.919 97.0429 161.888 101.687L160.805 275.627C160.775 280.27 164.687 284.068 169.525 284.097L169.524 284.105Z"
            fill="#2A2A7D"
          />
          <path
            d="M199.145 103.48L227.541 103.64C228.819 103.647 229.861 102.659 229.869 101.432V101.267C229.877 100.04 228.847 99.0407 227.569 99.0328L199.174 98.8731C197.895 98.8652 196.852 99.8549 196.845 101.081L196.844 101.245C196.837 102.472 197.867 103.473 199.145 103.48Z"
            fill="#2A2A7D"
          />
          <path
            d="M197.605 103.39L203.025 103.421C204.304 103.429 205.346 102.441 205.354 101.214L205.355 101.049C205.362 99.8224 204.332 98.8216 203.055 98.8148L197.634 98.7832C196.356 98.7754 195.313 99.7639 195.306 100.991L195.305 101.155C195.297 102.382 196.327 103.382 197.605 103.39Z"
            fill="#2A2A7D"
          />
          <path
            d="M156.466 142.916C155.501 142.759 154.76 141.948 154.774 140.976L154.886 122.591C154.893 121.514 155.807 120.639 156.938 120.647C158.06 120.662 158.973 121.54 158.964 122.617L158.852 141C158.845 142.078 157.931 142.954 156.8 142.945C156.684 142.943 156.578 142.934 156.474 142.917L156.466 142.916Z"
            fill="#101052"
          />
          <path
            d="M156.302 168.188C155.337 168.032 154.596 167.222 154.61 166.249L154.722 147.864C154.729 146.787 155.643 145.912 156.774 145.92C157.905 145.929 158.81 146.804 158.8 147.889L158.688 166.274C158.681 167.351 157.767 168.226 156.636 168.218C156.52 168.215 156.414 168.206 156.31 168.189L156.302 168.188Z"
            fill="#101052"
          />
          <path
            d="M277.016 122.096L308.892 119.037L356.612 128.664L352.955 221.065H270.828L277.016 122.096Z"
            fill="#3A3A94"
          />
          <path
            d="M355.118 175.537L323.805 221.063H352.962L355.118 175.537Z"
            fill="#101052"
          />
          <path
            d="M352.955 221.068L361.581 285.398H279.641L271.016 221.068H352.955Z"
            fill="#101052"
          />
          <path
            d="M341.711 241.131L340.117 241.401L347.242 285.487L348.836 285.217L341.711 241.131Z"
            fill="white"
          />
          <path
            d="M351.36 239.059H342.266C337.86 239.059 334.016 236.18 332.984 232.042L331.578 226.553L333.172 226.193L334.578 231.682C335.422 235.101 338.61 237.44 342.266 237.44H351.36V239.059Z"
            fill="white"
          />
          <path
            d="M321.555 285.573L311.992 252.644L302.992 248.325L301.398 238.338L302.992 238.068L304.399 247.245L313.305 251.474L323.055 285.034L321.555 285.573Z"
            fill="white"
          />
          <path
            d="M313.673 187.684L276.265 168.88C276.265 168.88 236.045 146.747 233.42 147.556C230.795 148.366 244.858 159.523 244.858 159.523C244.858 159.523 241.296 160.332 242.608 163.391C242.608 163.391 239.889 164.921 242.046 167.26C242.046 167.26 241.202 168.52 242.514 170.679L249.171 175.987L255.921 182.555C258.171 184.355 260.984 185.345 263.796 185.435L302.328 222.143C316.954 233.479 338.892 227.811 345.642 210.896L370.955 147.467L340.673 136.67L313.673 187.684Z"
            fill="#FFCBCB"
          />
          <path
            d="M240.922 165.373L245.985 167.442L245.328 168.882L240.266 166.813L240.922 165.373Z"
            fill="#101052"
          />
          <path
            d="M241.867 161.506L247.024 163.485L246.367 164.925L241.211 162.945L241.867 161.506Z"
            fill="#101052"
          />
          <path
            d="M244.125 157.998L248.438 159.707L247.781 161.147L243.469 159.438L244.125 157.998Z"
            fill="#101052"
          />
          <path
            d="M303.172 181.568L302.328 182.918L320.516 193.085L321.36 191.735L303.172 181.568Z"
            fill="#101052"
          />
          <path
            d="M358.592 159.439C367.405 157.549 372.936 149.272 371.061 140.814C369.092 132.357 360.467 127.049 351.654 128.848C342.842 130.738 337.31 139.015 339.185 147.472C341.154 155.93 349.779 161.328 358.592 159.439Z"
            fill="#3A3A94"
          />
          <path
            d="M340.204 137.396L320.047 175.904L354.36 189.76L370.298 150.172L340.204 137.396Z"
            fill="#3A3A94"
          />
          <path
            d="M198.748 102.122C198.748 102.122 198.748 105.811 201.654 105.811C204.56 105.811 205.685 94.9243 204.373 90.6057C203.06 86.287 196.498 89.3461 197.435 91.6853C198.373 94.0246 198.748 102.122 198.748 102.122Z"
            fill="#FFCBCB"
          />
          <path
            d="M191.422 93.1207C191.422 93.1207 191.891 101.488 196.11 104.097C200.328 106.706 199.016 93.1207 197.422 91.6812C195.734 90.3316 192.078 89.7018 191.422 93.1207Z"
            fill="#FFCBCB"
          />
          <path
            d="M204.353 90.6077C204.353 90.6077 203.51 103.114 205.385 106.803C206.603 109.052 210.166 106.623 210.166 103.474C210.166 101.764 210.822 94.2966 211.104 90.8776C211.291 89.2582 210.072 87.8186 208.385 87.6387C207.166 87.6387 205.666 88.2685 204.353 90.6077Z"
            fill="#FFCBCB"
          />
          <path
            d="M211.113 92.0408C211.113 92.0408 213.551 87.3622 217.582 90.6012C220.863 93.3004 216.644 101.758 214.957 104.727C214.394 105.716 213.457 106.346 212.332 106.526C211.207 106.616 209.988 106.166 210.175 103.467C210.457 98.4287 211.113 92.0408 211.113 92.0408Z"
            fill="#FFCBCB"
          />
          <path
            d="M199.485 102.208L197.891 102.028C198.735 96.0904 196.86 92.4015 196.86 92.3116L198.36 91.5918C198.453 91.8617 200.422 95.8205 199.485 102.208Z"
            fill="#101052"
          />
          <path
            d="M203.047 105.989L201.828 104.999C205.203 101.04 203.516 90.8736 203.516 90.7836L205.109 90.5137C205.203 90.9635 206.985 101.58 203.047 105.989Z"
            fill="#101052"
          />
          <path
            d="M209.422 107.159L208.391 105.899C209.984 104.729 210.453 96.0922 210.266 90.334H211.953C211.953 92.7632 212.141 105.089 209.422 107.159Z"
            fill="#101052"
          />
          <path
            d="M327.36 160.607L319.391 175.543L320.891 176.262L328.86 161.327L327.36 160.607Z"
            fill="white"
          />
          <path
            d="M295.492 122.812C294.836 122.812 294.086 122.722 293.43 122.722C298.211 122.992 296.992 103.108 296.992 100.589C296.992 96.2703 296.43 91.7717 296.805 87.543C297.367 81.7849 303.18 77.1963 308.993 76.2066C321.743 74.0473 329.899 85.0238 335.15 94.4708C336.462 96.9001 346.119 120.113 347.431 119.933C330.462 121.642 312.836 123.532 295.492 122.812Z"
            fill="#101052"
          />
          <path
            d="M308.43 104.553C308.43 104.553 309.18 121.827 309.18 131.184C309.18 137.752 315.461 137.572 315.461 137.572C315.461 137.572 331.587 137.212 327.368 127.496C326.805 126.236 326.336 124.976 325.961 123.717C324.555 119.128 320.993 109.771 321.18 104.553H308.43Z"
            fill="#FFCBCB"
          />
          <path
            d="M317.328 111.029L308.797 113.009L308.984 119.127C308.891 119.037 316.86 116.787 317.328 111.029Z"
            fill="#101052"
          />
          <path
            d="M322.125 111.749C322.125 111.749 304.031 116.878 298.874 111.029C295.218 106.801 299.531 93.9348 302.343 86.827C303.749 83.3181 306.656 80.619 310.406 79.5393C315.281 78.0998 322.5 78.0998 322.781 85.2975C323.25 97.3537 322.125 111.749 322.125 111.749Z"
            fill="#FFCBCB"
          />
          <path
            d="M301.951 94.837C300.92 96.3665 299.513 97.7161 296.044 100.325C295.294 100.865 295.576 102.035 296.419 102.215L300.169 102.485C301.201 102.574 302.138 101.855 302.326 100.865L303.357 95.8267C303.451 94.4771 302.42 94.2072 301.951 94.837Z"
            fill="#EB8D90"
          />
          <path
            d="M306.268 95.1052C306.174 95.735 306.455 96.3648 306.924 96.4547C307.393 96.5447 307.768 96.0049 307.862 95.2851C307.956 94.5654 307.674 94.0255 307.205 93.9355C306.83 93.9355 306.362 94.4754 306.268 95.1052Z"
            fill="#101052"
          />
          <path
            d="M300.18 92.584C300.273 92.584 300.367 92.584 300.461 92.584C300.93 92.674 301.211 93.2138 301.117 93.9336C301.023 94.5634 300.648 95.1032 300.18 95.1032C299.898 95.1032 299.711 94.8333 299.617 94.4734C299.711 93.7536 299.992 93.2138 300.18 92.584Z"
            fill="#101052"
          />
          <path
            d="M325.022 106.442C328.866 101.763 331.209 95.825 330.834 89.8869C330.459 83.9488 326.897 78.1006 321.365 75.4915C317.897 73.872 313.771 73.5121 310.021 74.5918C306.271 75.6714 303.084 78.1906 301.115 81.4296C297.927 86.8279 309.646 88.8073 312.646 89.0772C314.146 89.2571 315.459 89.0772 317.053 88.8972C318.084 88.8073 317.803 89.797 317.897 90.4268C317.897 90.7866 318.459 96.095 318.272 96.095C319.772 96.2749 320.709 94.9253 321.553 96.3649C322.303 97.6245 322.397 99.154 322.397 100.504C322.397 103.383 323.803 108.331 322.49 110.94C322.397 111.12 322.303 111.39 322.209 111.57C322.115 113.1 324.553 107.071 325.022 106.442Z"
            fill="#101052"
          />
          <path
            d="M318.172 96.1894C318.172 96.1894 321.641 90.8811 323.797 93.9401C325.86 97.0891 327.36 100.598 324.828 102.127C322.297 103.567 319.109 102.217 319.109 102.217L318.172 96.1894Z"
            fill="#FFCBCB"
          />
          <path
            d="M334.309 87.1006C335.246 86.8307 336.09 86.3808 336.84 85.931C338.903 84.6714 340.965 82.782 341.246 80.4427C341.528 78.8232 340.871 77.1138 339.84 75.8542C338.434 74.1447 336.09 73.155 333.84 73.4249C331.402 73.6948 329.246 75.4943 328.121 77.5636C327.09 79.543 326.527 82.4221 327.09 84.5814C327.558 86.3808 329.621 87.4605 331.496 87.5505C332.434 87.6404 333.465 87.4605 334.309 87.1006Z"
            fill="#101052"
          />
          <path
            d="M75.1431 285.955L85.3218 252.912L28.8301 253.741L19.0781 285.955H75.1431Z"
            fill="#101052"
          />
          <path
            d="M75.7478 286.741H17.9766L28.2045 252.961L86.4155 252.107L75.7478 286.741ZM20.1621 285.166H74.5239L84.2104 253.713L29.4416 254.516L20.1621 285.166Z"
            fill="white"
          />
          <path
            d="M43.5083 253.135L33.75 285.348L35.3255 285.788L45.0837 253.575L43.5083 253.135Z"
            fill="white"
          />
          <path
            d="M94.8665 285.955L84.6877 252.912L141.179 253.741L150.931 285.955H94.8665Z"
            fill="#101052"
          />
          <path
            d="M152.025 286.741H94.2537L83.5859 252.107L141.8 252.961L152.028 286.741H152.025ZM95.4775 285.166H149.839L140.56 254.516L85.791 253.713L95.4775 285.166Z"
            fill="white"
          />
          <path
            d="M130.119 148.03C136.956 141.469 136.956 130.832 130.119 124.271C123.283 117.709 112.198 117.709 105.362 124.271C98.5252 130.832 98.5252 141.469 105.362 148.03C112.198 154.59 123.283 154.59 130.119 148.03Z"
            fill="#6F6FC7"
          />
          <path
            d="M66.9992 119.35H117.745V179.086L56.8828 176.971L66.9992 119.35Z"
            fill="#6F6FC7"
          />
          <path
            d="M131.245 125.455L160.836 164.868H117.742V143.036L131.245 125.455Z"
            fill="#6F6FC7"
          />
          <path
            d="M172.69 162.081V161.068H171.635H35.6953H34.6406V162.081V256.978V257.99H35.6953H171.635H172.69V256.978V162.081Z"
            fill="#FFC654"
            stroke="#101052"
            stroke-width="2.02436"
          />
          <path
            d="M126.921 162.076H35.7031V256.974H126.921V162.076Z"
            fill="#FFDB80"
          />
          <path
            d="M127.109 256.757C126.465 256.757 125.938 256.357 125.938 255.868V163.177C125.938 162.687 126.465 162.287 127.109 162.287C127.754 162.287 128.281 162.687 128.281 163.177V255.868C128.281 256.357 127.754 256.757 127.109 256.757Z"
            fill="white"
          />
          <path
            d="M79.3767 148.03C86.2132 141.469 86.2131 130.832 79.3767 124.271C72.5401 117.709 61.4561 117.709 54.6196 124.271C47.7831 130.832 47.783 141.469 54.6196 148.03C61.456 154.59 72.5402 154.59 79.3767 148.03Z"
            fill="#6F6FC7"
          />
          <path
            d="M54.4491 124.439L9.25199 161.661C0.59578 168.79 -2.28515 180.464 2.11836 190.562L18.0721 227.148C19.7751 231.049 20.8876 235.164 21.3765 239.368L22.0131 244.819C22.3839 248.01 23.5324 251.07 25.3765 253.747C27.7456 257.189 31.2304 261.043 34.4822 260.067C34.4822 260.067 40.4772 265.82 45.0645 262.098C45.0645 262.098 50.9644 264.806 54.4491 260.237C54.4491 260.237 62.6983 261.928 63.581 257.699C64.4637 253.467 58.8199 254.654 58.8199 254.654C58.8199 254.654 50.0325 243.985 50.1932 234.002C50.1932 234.002 60.431 251.155 64.4638 243.995C67.9945 237.722 53.1038 222.374 45.1695 214.86C42.0687 211.922 39.8538 208.234 38.7643 204.185L32.9005 182.394L77.5563 149.562L54.4557 124.445L54.4491 124.439Z"
            fill="#EDA37E"
          />
          <path
            d="M32.2934 176.881L30.7031 177.268L33.3748 187.372L34.965 186.984L32.2934 176.881Z"
            fill="#101052"
          />
          <path
            d="M34.0153 260.706C29.9563 258.032 27.5314 249.953 27.4297 249.608L29.008 249.178C29.031 249.256 31.3739 257.054 34.944 259.405L34.0153 260.703V260.706Z"
            fill="#101052"
          />
          <path
            d="M44.6548 262.777C36.0183 258.154 34.5155 248.15 34.4531 247.725L36.0775 247.514C36.0906 247.608 37.5441 257.169 45.4522 261.401L44.6515 262.777H44.6548Z"
            fill="#101052"
          />
          <path
            d="M54.0403 260.921C43.1594 255.029 42.247 246.042 42.2109 245.664L43.845 245.529C43.8516 245.614 44.7507 254.084 54.8441 259.551L54.0369 260.921H54.0403Z"
            fill="#101052"
          />
          <path
            d="M55.1816 123.764L23.5625 149.881L42.6731 175.203L77.557 149.557L55.1816 123.764Z"
            fill="#6F6FC7"
          />
          <path
            d="M106.645 99.3019C106.645 99.3019 103.193 120.721 105.083 130.291C106.973 139.864 96.3479 140.05 96.3479 140.05C96.3479 140.05 75.6819 140.673 83.8065 124.046C83.8065 124.046 88.2921 106.951 87.4553 96.7793L106.645 99.3019Z"
            fill="#EDA37E"
          />
          <path
            d="M105.58 106.902L104.746 116.317C104.599 116.296 104.454 116.27 104.31 116.245C97.8918 115.112 92.7663 110.46 91.0174 104.43L90.5547 102.834L105.58 106.902Z"
            fill="#101052"
          />
          <path
            d="M102.239 108.172C107.922 109.169 114.013 107.201 112.926 101.417C111.26 92.5427 109.091 82.2364 107.273 77.7367C103.772 69.0646 91.4172 76.4108 91.4172 76.4108C91.4172 76.4108 84.3459 93.7865 86.9775 98.4887C91.5747 106.701 102.239 108.172 102.239 108.172Z"
            fill="#EDA37E"
          />
          <path
            d="M86.0552 97.3034C85.773 96.3463 85.4941 95.3893 85.2119 94.4322C84.8346 93.1411 85.4415 92.2684 85.4481 90.984C85.4481 90.316 85.12 89.6929 84.9396 89.0474C84.5524 87.6618 84.8871 86.1087 85.8157 84.9852C84.4835 83.0328 84.7788 80.2021 86.4851 78.5387C85.901 77.1532 85.914 75.5472 86.5145 74.1683C87.1872 72.622 89.097 71.0632 90.8886 72.1339C90.6754 72.0079 91.9715 70.6662 92.106 70.5594C92.6901 70.1061 93.3365 69.879 94.0584 69.7249C95.5678 69.3976 97.0805 69.744 98.439 70.4334C99.2134 68.9972 101.021 68.2359 102.698 68.409C104.372 68.5822 105.875 69.5742 106.888 70.8653C107.604 69.9925 108.962 69.7373 110.038 70.1433C111.115 70.5504 111.892 71.5266 112.194 72.5972C112.497 73.6679 112.368 74.8195 112.007 75.8744C111.44 77.5412 110.193 79.0932 108.441 79.5936C106.275 80.2144 103.971 79.0999 102.265 77.6829C100.667 76.3535 99.1478 76.0926 97.7105 77.8842C96.5129 79.38 95.8729 81.1839 95.4365 83.0013C95.21 83.9393 95.0394 84.8907 94.8557 85.8354C94.708 86.5945 94.6786 87.6551 94.1601 88.2849C93.9075 88.5908 93.4514 88.773 93.0839 88.6065C91.8894 88.0521 92.4998 85.4227 92.4801 84.4622C92.4571 83.3533 92.0962 82.1252 91.0921 81.5775C90.2717 85.8635 89.5825 90.1687 89.0247 94.4918C88.8377 95.9493 88.6409 97.471 87.8042 98.6991C87.686 98.8757 87.5384 99.0511 87.3317 99.1118C86.8099 99.2625 86.4391 98.6294 86.2915 98.1255C86.2127 97.8522 86.1307 97.5778 86.0519 97.3034H86.0552Z"
            fill="#101052"
          />
          <path
            d="M102.667 82.6168C102.631 83.2657 102.943 83.81 103.369 83.8325C103.792 83.8539 104.17 83.3478 104.206 82.6989C104.242 82.05 103.93 81.5056 103.504 81.4831C103.077 81.4606 102.703 81.9679 102.667 82.6168Z"
            fill="#101052"
          />
          <path
            d="M107.616 83.1839C107.725 82.7621 108.292 82.6485 108.564 82.9983C108.564 82.9983 110.491 85.215 113.218 90.0712C113.683 90.9023 113.046 91.8751 112.095 91.6389L108.21 90.6751C106.838 90.3355 106.005 89.0028 106.34 87.6802L107.619 83.1839H107.616Z"
            fill="#BF714B"
          />
          <path
            d="M92.4862 82.8653C92.4862 82.8653 89.953 78.0214 87.3213 81.4448C84.6897 84.8671 82.7931 88.854 85.5428 90.722C88.2926 92.5889 92.0235 91.2281 92.0235 91.2281L92.4862 82.8653Z"
            fill="#EDA37E"
          />
          <path
            d="M88.2983 84.9277L87.6172 86.3594L90.1666 87.4762L90.8477 86.0434L88.2983 84.9277Z"
            fill="#101052"
          />
          <path
            d="M110.229 103.592L108.594 103.726L108.773 105.756L110.408 105.623L110.229 103.592Z"
            fill="#101052"
          />
          <path
            d="M173.532 209.264C173.532 209.264 192.553 212.539 183.004 222.049C183.004 222.049 188.763 225.279 183.286 229.165C183.286 229.165 186.447 232.811 181.699 234.843C181.699 234.843 185.685 237.409 180.054 240.318C177.377 241.704 174.299 242.218 171.28 241.865C166.178 241.27 158 239.478 159.012 234.103C159.012 234.103 153.092 229.335 158.148 227.839C158.148 227.839 149.877 221.23 160.317 219.381C160.317 219.381 155.73 215.039 160.308 213.861C161.863 213.461 163.516 213.657 164.97 214.318C167.654 215.543 173.065 217.656 174.831 215.766C176.242 214.255 174.686 215.373 173.534 215.583V209.264H173.532Z"
            fill="#EDA37E"
          />
          <path
            d="M171.122 225.387C163.546 224.188 159.251 219.426 159.07 219.221L160.32 218.201C160.359 218.245 164.428 222.729 171.391 223.835L171.126 225.387H171.122Z"
            fill="#101052"
          />
          <path
            d="M171.284 232.526C163.646 232.526 157.414 228.218 157.148 228.032L158.116 226.76C158.179 226.804 164.387 231.093 171.652 230.948L171.685 232.522C171.55 232.522 171.415 232.526 171.284 232.526Z"
            fill="#101052"
          />
          <path
            d="M172.378 238.773C163.196 238.424 158.659 234.855 158.469 234.705L159.521 233.498C159.564 233.533 163.867 236.874 172.444 237.202L172.378 238.776V238.773Z"
            fill="#101052"
          />
          <path
            d="M173.613 201.177C173.613 201.177 182.996 199.779 185.699 206.089C188.288 212.132 173.531 209.266 173.531 209.266L173.613 201.177Z"
            fill="#EDA37E"
          />
          <path
            d="M172.358 217.334C169.322 217.334 165.618 215.514 165.086 215.246L165.851 213.855C168.15 215.016 172.39 216.458 173.978 215.388C174.615 214.957 174.839 214.047 174.635 212.683L176.259 212.459C176.554 214.456 176.108 215.876 174.924 216.676C174.219 217.151 173.322 217.338 172.358 217.338V217.334Z"
            fill="#101052"
          />
          <path
            d="M70.5626 153.709L42.1719 174.586L43.1698 175.835L71.5605 154.958L70.5626 153.709Z"
            fill="#101052"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3081_105838">
          <rect width="386" height="268" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
)
