import { HStack, Spacer, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'
import { Row } from '@tanstack/react-table'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { AdminCampaignLayout } from '~/layouts/AdminCampaignLayout'
import { DistributorView } from '~/types/distributor'

import { BulkUploadDistributorModal } from '../components/BulkUploadDistributorModal'
import { EmptyDistributorsCard } from '../components/EmptyDistributorsCard'
import { useDeleteDistributor } from '../hooks/useDeleteDistributor'

import { TableSkeleton } from '~components/DataTable'
import { DistributorsTable } from '~components/DistributorsTable'
import { useIsCampaignOnboardingDisabled } from '~features/campaign/hooks/useIsCampaignOnboardingDisabled'
import { useCampaign } from '~hooks/useCampaign'
import { useDistributors } from '~hooks/useDistributors'
import { useAuth } from '~lib/auth'

export const DistributorsPage = (): JSX.Element => {
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)
  const { distributors, isDistributorsLoading } = useDistributors(campaignId)
  const isCampaignOnboardingDisabled =
    useIsCampaignOnboardingDisabled(campaignId)
  const auth = useAuth()

  const {
    isOpen: isBulkAddOpen,
    onOpen: onBulkAddOpen,
    onClose: onBulkAddClose,
  } = useDisclosure()

  const data = distributors ?? []
  const hasData = data.length > 0

  const distributorCountString =
    data.length > 0
      ? `${Intl.NumberFormat('en').format(data.length)} ${
          data.length > 1 ? 'distributors' : 'distributor'
        }`
      : ''

  const { deleteDistributor, isDeletingDistributor } =
    useDeleteDistributor(campaignId)

  const handleDeleteRow = async (rowToDelete: Row<DistributorView>) => {
    const distributorToDelete = rowToDelete.original
    if (distributorToDelete.userId === auth.user?.id) {
      showErrorToast({
        title: 'You cannot remove yourself as a distributor',
      })
      return
    }

    try {
      await deleteDistributor(distributorToDelete.id)
      showSuccessToast({ title: 'Distributor deleted successfully' })
    } catch (e) {
      showErrorToast({
        title: 'Unable to delete distributor',
      })
    }
  }

  const showErrorToast = useToast({ status: 'error' })
  const showSuccessToast = useToast({ status: 'success' })

  return (
    <AdminCampaignLayout
      campaignId={campaignId}
      campaignName={campaign?.name}
      campaignStatus={campaign?.status}
      sidebarActiveIndex={2}
    >
      <VStack
        alignItems="stretch"
        spacing={8}
        py={12}
        px={6}
        bg="base.canvas.alt"
        minH="$100vh"
        // override the base margin
        ml="0px !important"
        w="100%"
      >
        <HStack justifyContent="space-between" alignItems="flex-end">
          <VStack alignItems="flex-start" spacing={1}>
            <Text textStyle="h3" fontWeight="600">
              Distributors
            </Text>
            {!_.isEmpty(distributorCountString) && (
              <Text textStyle="subhead-1" textColor="brand.secondary.500">
                {distributorCountString}
              </Text>
            )}
          </VStack>
          <Spacer />
          {hasData && (
            <Button
              onClick={onBulkAddOpen}
              isDisabled={isCampaignOnboardingDisabled}
            >
              <Text textStyle="subhead-2">Bulk Upload Distributors</Text>
            </Button>
          )}
        </HStack>
        {isDistributorsLoading ? (
          <TableSkeleton />
        ) : hasData ? (
          <DistributorsTable
            handleDeleteRow={handleDeleteRow}
            isDeletingDistributor={isDeletingDistributor}
            data={data}
          />
        ) : (
          <EmptyDistributorsCard
            handleOnUploadMultipleDistributorsButtonClick={onBulkAddOpen}
          />
        )}
      </VStack>

      <BulkUploadDistributorModal
        campaignId={campaignId}
        isOpen={isBulkAddOpen}
        showDataOverrideWarning={data.length > 0}
        onOpen={onBulkAddOpen}
        onClose={onBulkAddClose}
      />
    </AdminCampaignLayout>
  )
}
