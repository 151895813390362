// Adapted from PaySG
// https://github.com/opengovsg/paysg/blob/a9b5aee39aaecf3009b8822b815de8c76207aacf/frontend/src/components/NextPrevPagination.tsx

import { Box, HStack, Text } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'
import { useState } from 'react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

type PaginationProps = {
  onClickNext?: (() => void) | null
  onClickPrevious?: (() => void) | null
  isLoadingPreviousPage: boolean
  isLoadingNextPage: boolean
  count?: number
  limit?: number
}

export const PaginationNavigation = ({
  onClickNext,
  onClickPrevious,
  isLoadingPreviousPage,
  isLoadingNextPage,
  count,
  limit,
}: PaginationProps) => {
  const [page, setPage] = useState(0)
  const maxPage = !!count && !!limit ? Math.ceil(count / limit) - 1 : Infinity
  const minPage = 0

  const handlePageChange = (newPageNumber: number) => {
    const getNewPageValues =
      newPageNumber > page ? onClickNext : onClickPrevious

    if (newPageNumber > maxPage) {
      setPage(maxPage)
      return
    }

    if (newPageNumber < minPage) {
      setPage(minPage)
      return
    }

    setPage(newPageNumber)
    getNewPageValues?.()
  }

  return (
    <Box>
      {onClickPrevious || onClickNext ? (
        <HStack justifyContent="flex-end">
          {limit && (
            <Text>
              Showing <Text as="b">{1 + limit * page}</Text> to{' '}
              <Text as="b">{limit + limit * page}</Text> of{' '}
              {count && count > 0 && <Text as="b">{count}</Text>}
              results
            </Text>
          )}
          <IconButton
            icon={<HiChevronLeft />}
            isDisabled={
              !onClickPrevious || isLoadingNextPage || isLoadingPreviousPage
            }
            onClick={() => {
              handlePageChange(page - 1)
            }}
            isLoading={isLoadingPreviousPage}
            color="neutral.700"
            variant="link"
            aria-label="previous page"
          />
          <IconButton
            icon={<HiChevronRight />}
            isLoading={isLoadingNextPage}
            isDisabled={
              !onClickNext || isLoadingNextPage || isLoadingPreviousPage
            }
            onClick={() => {
              handlePageChange(page + 1)
            }}
            color="neutral.700"
            variant="link"
            aria-label="next page"
          />
        </HStack>
      ) : (
        ''
      )}
    </Box>
  )
}
