import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    _hover: { bg: 'transparent' },
    // Removes the focus styles applied to the radio component
    // by Chakra UI. Chakra UI by default has this style active
    // for accessibility purpose. Hence to remove the outline
    // when the radio button is clicked, we will override the
    // style here all across DistributeSG.
    _focusWithin: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
})

export const Radio = defineMultiStyleConfig({
  baseStyle,
})
