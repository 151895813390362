import { extendTheme } from '@chakra-ui/react'
// Importing from main so @chakra-cli can work properly without complaining about ESM.
import { theme as ogpTheme } from '@opengovsg/design-system-react'

import { colors } from './styles/colors'
import { shadows } from './styles/shadows'
import { spacing } from './styles/spacing'
import { textStyles } from './styles/textStyles'
import { typography } from './styles/typography'
import { components } from './components'

/**
 * Design system themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen/themes.
 * README for importing themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen.
 */
export const theme = extendTheme(ogpTheme, {
  components,
  colors: colors,
  shadows: shadows,
  space: spacing,
  fontSizes: typography.fontSize,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacing,
  textStyles,
})
