import {
  AdminGenerateOtpDtoRequestDto,
  GenerateOtpDtoResponseDto,
} from '~shared/types/auth.dto'

import { ApiService } from '~lib/helpers/api'

export const generateOtp = async (params: AdminGenerateOtpDtoRequestDto) => {
  if (params.email) {
    params.email = params.email.toLowerCase()
  }

  const response = await ApiService.post<GenerateOtpDtoResponseDto>(
    '/auth/admin',
    params,
  )
  return response.data
}
