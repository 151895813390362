import camelcaseKeys from 'camelcase-keys'

import {
  BaseAllocationResponseWithLocationDto,
  ListAllocationPaginatedResponseDto,
} from '~shared/types/allocation.dto'

import { usePaginatedQuery } from '~hooks/usePaginatedQuery'
import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'
import { reprintDateTime } from '~utils/date'

export const useAllocations = (campaignId: string, limit: number) => {
  const {
    entries,
    allEntries,
    isEntriesLoading,
    isFetchingEntries,
    isFetchingEntriesNextPage,
    isFetchingEntriesPreviousPage,
    getNextPageOfEntries,
    getPreviousPageOfEntries,
    refetchEntries,
    fetchEntriesError,
    fetchEntriesCurrentSearchQuery,
    refreshFetchEntries,
    updateFetchEntriesSearchQuery,
  } = usePaginatedQuery<BaseAllocationResponseWithLocationDto>({
    apiFunction: async (params) => {
      const response = await ApiService.get<ListAllocationPaginatedResponseDto>(
        `/campaigns/${campaignId}/allocations`,
        {
          params,
        },
      )

      return {
        // Specifying deep true otherwise types of nested objects don't resolve properly
        data: camelcaseKeys(response.data.data, { deep: true }),
        pageInfo: camelcaseKeys(response.data.page_info),
      }
    },
    limit,
    // API call is lightweight and we want to ensure data is consistent
    // between users viewing the allocations data within the same campaign
    refetchInterval: 5000, // 5 seconds
    ...queryKeys.campaigns.detail(campaignId)._ctx.allocations,
  })

  return {
    allocations: (entries ?? []).map((allocation) => {
      return {
        ...allocation,
        updatedAt: reprintDateTime(allocation.updatedAt),
      }
    }),
    allAllocations: allEntries,
    isAllocationsLoading: isEntriesLoading,
    fetchAllocations: refetchEntries,
    isFetchingAllocations: isFetchingEntries,
    getNextPageOfAllocations: getNextPageOfEntries,
    getPreviousPageOfAllocations: getPreviousPageOfEntries,
    isFetchingAllocationsNextPage: isFetchingEntriesNextPage,
    isFetchingAllocationsPreviousPage: isFetchingEntriesPreviousPage,
    fetchAllocationsError: fetchEntriesError,
    fetchAllocationsCurrentSearchQuery: fetchEntriesCurrentSearchQuery,
    refreshFetchAllocations: refreshFetchEntries,
    updateFetchAllocationsSearchQuery: updateFetchEntriesSearchQuery,
  }
}
