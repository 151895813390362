import {
  Box,
  Collapse,
  Step,
  StepIndicator,
  StepNumber,
  Stepper,
  StepperProps,
  StepSeparator,
  StepStatus,
  Text,
} from '@chakra-ui/react'
import * as react from 'react'
import { BiCheck } from 'react-icons/bi'

const LAST_CREATE_CAMPAIGN_INDEX = 5

export const DistributeStepper = ({
  index,
  children,
  ...props
}: StepperProps) => {
  return (
    <Stepper
      index={index}
      orientation="vertical"
      alignItems="stretch"
      gap="0"
      width="100%"
      {...props}
    >
      {children}
    </Stepper>
  )
}

export const DistributeStep = ({
  header,
  index,
  isActiveStep,
  isCompleteStep,
  setActiveStep,
  children,
}: {
  header?: string
  index: number
  isActiveStep: (step: number) => boolean
  isCompleteStep: (step: number) => boolean
  setActiveStep: react.Dispatch<react.SetStateAction<number>>
  children: React.ReactNode
}) => {
  const isLastStep = index === LAST_CREATE_CAMPAIGN_INDEX
  const isLastStepActive = isActiveStep(index) && isLastStep

  return (
    <Step key={index} style={{ width: '100%' }}>
      <StepIndicator
        // !important to override theme only for the last step
        backgroundColor={isLastStepActive ? 'green.500 !important' : 'blue'}
        borderColor={isLastStepActive ? 'green.500 !important' : 'blue'}
      >
        <StepStatus
          complete={isLastStep ? <BiCheck /> : <StepNumber />}
          incomplete={isLastStep ? <BiCheck /> : <StepNumber />}
          active={isLastStep ? <BiCheck /> : <StepNumber />}
        />
      </StepIndicator>
      {!isLastStep && (
        <Box width="100%" background="white" borderRadius="4px" mb={2} p={8}>
          {isCompleteStep(index) || isActiveStep(index) ? (
            <Text textStyle="h6" onClick={() => setActiveStep(index)}>
              {header}
            </Text>
          ) : (
            <Text textStyle="h6" color="interaction.support.disabled-content">
              {header}
            </Text>
          )}
          <Collapse in={isActiveStep(index)}>{children}</Collapse>
        </Box>
      )}
      {isLastStep && (
        <Box
          width="100%"
          borderRadius="4px"
          background={isActiveStep(index) ? 'white' : 'transparent'}
          mb={2}
          p={8}
        >
          <Collapse in={isActiveStep(index)}>{children}</Collapse>
        </Box>
      )}
      <StepSeparator />
    </Step>
  )
}
