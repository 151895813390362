import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Cell, Pie, PieChart } from 'recharts'

import { useCampaignStatistics } from '~hooks/useCampaignStatistics'

type DistributionData = {
  name: string
  value: number
  color: string
}

interface DistributionBreakdownPieChartProps {
  data: DistributionData[]
}

interface DistributionPieChartLegendProps {
  colourHexCode: string
  description: string
  value: number
  hasTooltip: boolean
  isLoaded: boolean
  borderColor?: string
  tooltipDescription?: React.ReactNode
}

interface DistributionBreakdownSectionProps {
  campaignId: string
  isWalkInCampaign: boolean
}

const DistributionBreakdownPieChart = ({
  data,
}: DistributionBreakdownPieChartProps) => {
  return (
    <PieChart width={160} height={160}>
      <Pie dataKey="value" data={data} labelLine={false} outerRadius={80}>
        {data.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={data[index].color} />
        ))}
      </Pie>
    </PieChart>
  )
}

const DistributionPieChartLegend = ({
  colourHexCode,
  borderColor,
  description,
  value,
  isLoaded,
  hasTooltip,
  tooltipDescription,
}: DistributionPieChartLegendProps) => {
  return (
    <HStack width="17rem">
      <Box
        width="12px"
        height="12px"
        bg={colourHexCode}
        border={borderColor ? `1px solid ${borderColor}` : '0px'}
        borderRadius="50%"
      />
      <Text textStyle="body-2">{description}</Text>
      {hasTooltip && (
        <Tooltip label={tooltipDescription} placement="top" hasArrow>
          <InfoOutlineIcon />
        </Tooltip>
      )}
      <Spacer />
      <Skeleton isLoaded={isLoaded} startColor="grey.100" endColor="grey.50">
        <Text textStyle="body-2">{Intl.NumberFormat('en').format(value)}</Text>
      </Skeleton>
    </HStack>
  )
}

const DistributionBreakdown = ({
  uniqueDistributionCount,
  appealDistributionCount,
  totalDistributionCount,
  isWalkInCampaign,
  isLoaded,
}: {
  uniqueDistributionCount: number
  appealDistributionCount: number
  totalDistributionCount: number
  isWalkInCampaign: boolean
  isLoaded: boolean
}) => {
  const hasDistributionData = totalDistributionCount > 0

  const piechartData: DistributionData[] = [
    {
      name: 'Unique distributions',
      value: uniqueDistributionCount,
      color: '#63B1AA',
    },
    {
      name: 'Appeals',
      // Set appeal distribution count to be 1 if there is no distribution
      // data so that the piechart is still shown.
      value: hasDistributionData ? appealDistributionCount : 1,
      color: '#E2EEED',
    },
  ]

  const uniqueDistributionTooltipDescription = isWalkInCampaign
    ? 'These are the first distributions given to each recipient. Subsequent distributions (appeals) are not included.'
    : 'These are distributions made according to your predefined allocations. Appeals are not included in this number.'

  const appealDistributionTooltipDescription = isWalkInCampaign
    ? 'An appeal occurs when a recipient receives any subsequent distribution after the first one.'
    : 'Appeals occur when recipients are given more items than they were allocated, or when distributions are made to unallocated individuals.'

  return (
    <HStack width="31rem">
      {isLoaded ? (
        <DistributionBreakdownPieChart data={piechartData} />
      ) : (
        <SkeletonCircle size="160" startColor="grey.100" endColor="grey.50" />
      )}
      <Spacer />
      <VStack justify="center">
        <DistributionPieChartLegend
          colourHexCode="#63B1AA"
          description="Unique distributions"
          value={uniqueDistributionCount}
          hasTooltip={true}
          tooltipDescription={
            <Text textStyle="body-2">
              {uniqueDistributionTooltipDescription}
            </Text>
          }
          isLoaded={isLoaded}
        />
        <DistributionPieChartLegend
          colourHexCode="#E2EEED"
          description="Appeals"
          value={appealDistributionCount}
          hasTooltip={true}
          tooltipDescription={
            <Text textStyle="body-2">
              {appealDistributionTooltipDescription}
            </Text>
          }
          isLoaded={isLoaded}
        />
        <DistributionPieChartLegend
          colourHexCode="#FFFFFF"
          borderColor="#BFC2C8"
          description="Total distributions"
          value={totalDistributionCount}
          hasTooltip={false}
          isLoaded={isLoaded}
        />
      </VStack>
    </HStack>
  )
}

export const DistributionBreakdownSection = ({
  campaignId,
  isWalkInCampaign,
}: DistributionBreakdownSectionProps) => {
  const { campaignStatistics, isFetching } = useCampaignStatistics(campaignId)

  const totalDistributionCount =
    campaignStatistics?.total_distribution_count ?? 0
  const appealDistributionCount =
    campaignStatistics?.total_appeal_distribution_count ?? 0
  const uniqueDistributionCount =
    totalDistributionCount - appealDistributionCount

  return (
    <Box bg="white" borderRadius="4px">
      <Flex px={8} pt={8} mb={6}>
        <Text textStyle="h6" color="base.content.strong">
          Breakdown of Distributions
        </Text>
      </Flex>
      <Flex justifyContent="center" mb={12}>
        <DistributionBreakdown
          uniqueDistributionCount={uniqueDistributionCount}
          appealDistributionCount={appealDistributionCount}
          totalDistributionCount={totalDistributionCount}
          isWalkInCampaign={isWalkInCampaign}
          isLoaded={!isFetching}
        />
      </Flex>
    </Box>
  )
}
