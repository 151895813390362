import { Box, Divider, HStack, Skeleton, Text, VStack } from '@chakra-ui/react'

import { useCampaignStatistics } from '~hooks/useCampaignStatistics'

interface StatisticsProps {
  header: string
  statistics: number
  isLoaded: boolean
}

interface CampaignStatisticsCardProps {
  isWalkIn: boolean
  campaignId: string
}

const StatisticsColumn = ({
  header,
  statistics,
  isLoaded,
}: StatisticsProps) => {
  const stats = Intl.NumberFormat('en').format(statistics)
  return (
    <VStack px={8} py={6} justifyItems="center">
      <Text textStyle="subhead-2" px={8} color="base.content.medium">
        {header}
      </Text>
      <Skeleton isLoaded={isLoaded} startColor="grey.100" endColor="grey.50">
        <Text textStyle="h6" px={8}>
          {stats}
        </Text>
      </Skeleton>
    </VStack>
  )
}

const AllocationColumn = ({
  totalAllocationCount,
  isLoaded,
}: {
  totalAllocationCount: number
  isLoaded: boolean
}) => {
  return (
    <>
      <StatisticsColumn
        header="Allocation"
        statistics={totalAllocationCount}
        isLoaded={isLoaded}
      />
      <Divider orientation="vertical" h="60%" />
    </>
  )
}

const DistributionColumn = ({
  totalDistributionCount,
  isLoaded,
}: {
  totalDistributionCount: number
  isLoaded: boolean
}) => {
  return (
    <>
      <StatisticsColumn
        header="Distributions"
        statistics={totalDistributionCount}
        isLoaded={isLoaded}
      />
      <Divider orientation="vertical" h="60%" />
    </>
  )
}

const LocationColumn = ({
  totalLocationCount,
  isLoaded,
}: {
  totalLocationCount: number
  isLoaded: boolean
}) => {
  return (
    <>
      <StatisticsColumn
        header="Locations"
        statistics={totalLocationCount}
        isLoaded={isLoaded}
      />
      <Divider orientation="vertical" h="60%" />
    </>
  )
}

const DistributorsColumn = ({
  totalDistributorCount,
  isLoaded,
}: {
  totalDistributorCount: number
  isLoaded: boolean
}) => {
  return (
    <>
      <StatisticsColumn
        header="Distributors"
        statistics={totalDistributorCount}
        isLoaded={isLoaded}
      />
    </>
  )
}

export const CampaignStatisticsCard = ({
  isWalkIn,
  campaignId,
}: CampaignStatisticsCardProps) => {
  const { campaignStatistics, isFetching } = useCampaignStatistics(campaignId)

  const totalAllocationCount = campaignStatistics?.total_allocation_count ?? 0
  const totalDistributionCount =
    campaignStatistics?.total_distribution_count ?? 0
  const totalLocationCount = campaignStatistics?.total_location_count ?? 0
  const totalDistributorCount = campaignStatistics?.total_distributor_count ?? 0

  return (
    <Box bg="white" borderRadius="4px">
      <HStack px={4} h="6rem" justifyContent="space-evenly">
        {!isWalkIn && (
          <AllocationColumn
            totalAllocationCount={totalAllocationCount}
            isLoaded={!isFetching}
          />
        )}
        <DistributionColumn
          totalDistributionCount={totalDistributionCount}
          isLoaded={!isFetching}
        />
        <LocationColumn
          totalLocationCount={totalLocationCount}
          isLoaded={!isFetching}
        />
        <DistributorsColumn
          totalDistributorCount={totalDistributorCount}
          isLoaded={!isFetching}
        />
      </HStack>
    </Box>
  )
}
