import { useNavigate, useParams } from 'react-router-dom'

import { PrivateElement } from '~/app/PrivateElement'

import { AllocationsPage } from './AllocationsPage'

import { useCampaign } from '~hooks/useCampaign'

export const AllocationRoutes = () => {
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)
  const navigate = useNavigate()

  const isWalkInCampaign = campaign?.isWalkIn

  // If campaign is a walk in campaign, redirect user to
  // locations route instead.
  if (isWalkInCampaign) {
    navigate(`/${campaign.id}/locations`, { replace: true })
  }

  return <PrivateElement element={<AllocationsPage />} />
}
