import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteLocationById } from '../api/deleteLocation'

import { queryKeys } from '~lib/constants/query'

export const useDeleteLocation = (campaignId: string) => {
  const queryCache = useQueryClient()

  const { mutateAsync: deleteLocation, isLoading: isDeletingLocation } =
    useMutation({
      mutationFn: (locationId: string) =>
        deleteLocationById(campaignId, locationId),
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          queryKeys.campaigns.detail(campaignId)._ctx.locations, // invalidate all since we don't have the locationId
        )
      },
    })

  return {
    deleteLocation,
    isDeletingLocation,
  }
}
