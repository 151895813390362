import { useQuery } from '@tanstack/react-query'

import { GetDistributionCountResponseDto } from '~shared/types/distribution.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export const useDistributionCount = (campaignId: string) => {
  const getDistributionCount = async () => {
    const response = await ApiService.get<GetDistributionCountResponseDto>(
      `/campaigns/${campaignId}/distributions/count`,
    )

    return response.data.count
  }

  const { data: distributionCount } = useQuery({
    ...queryKeys.campaigns.detail(campaignId)._ctx.distributionCount,
    queryFn: getDistributionCount,
    // API call is lightweight and we want to ensure data is consistent
    // between users viewing the distribution count within the same campaign
    // hence we set a shorter refetchInterval
    refetchInterval: 5000, // 5 seconds
  })

  return {
    distributionCount,
  }
}
