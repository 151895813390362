import snakecaseKeys from 'snakecase-keys'
import { CamelCasedProperties } from 'type-fest'

import {
  UpdateLocationRequestDto,
  UpdateLocationResponseDto,
} from '~shared/types/location.dto'

import { ApiService } from '~lib/helpers/api'

export const updateLocationById = async (
  campaignId: string,
  locationId: string,
  updateLocationData: CamelCasedProperties<UpdateLocationRequestDto>,
) => {
  const res = await ApiService.put<UpdateLocationResponseDto>(
    `/campaigns/${campaignId}/locations/${locationId}`,
    snakecaseKeys(updateLocationData, { deep: true }),
  )

  return res.data
}
