import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { PaginatedResponse } from '~shared/types/pagination.dto'

import { scrollToTop } from '~utils/scroll'

type PageParams = {
  before?: string
  after?: string
}

type UsePaginatedQueryParams<
  ResponseObject,
  SearchParams extends Record<string, string> = Record<string, string>,
> = {
  apiFunction: (params: {
    limit: number
    search?: SearchParams
    after?: string
    before?: string
  }) => Promise<CamelCasedPropertiesDeep<PaginatedResponse<ResponseObject>>>
  queryKey: QueryKey
  enabled?: boolean | (({ search }: { search?: SearchParams }) => boolean)
  limit?: number
  refetchInterval: number | false
}

export const usePaginatedQuery = <
  ResponseObject,
  SearchParams extends Record<string, string> = Record<string, string>,
>({
  apiFunction,
  queryKey,
  refetchInterval = false,
  enabled = true,
  limit = 10,
}: UsePaginatedQueryParams<ResponseObject, SearchParams>) => {
  const [searchQuery, setCurrentSearchQuery] = useState<SearchParams>()
  const [currentPage, setCurrentPage] = useState(0)

  // When search query is updated, include all recently
  // created entries.
  const updateSearch = useCallback(
    (newSearchValues: SearchParams | undefined) => {
      setCurrentPage(0)
      setCurrentSearchQuery(newSearchValues)
    },
    [],
  )

  // To include all recently created entries.
  // e.g After creating a new entry, users expect
  // all new entries to show up.
  const refresh = useCallback(() => {
    setCurrentPage(0)
  }, [])

  const fetchEntries = async (
    fetchOptions: QueryFunctionContext<QueryKey, PageParams>,
  ) => {
    const { pageParam } = fetchOptions
    const queryResponse = await apiFunction({
      limit,
      search: searchQuery, // If search == '', then put undefined as well
      after: pageParam?.after,
      before: pageParam?.before,
    })

    return queryResponse
  }

  const query = useInfiniteQuery<
    CamelCasedPropertiesDeep<PaginatedResponse<ResponseObject>>
  >([...queryKey, searchQuery], fetchEntries, {
    getNextPageParam: (lastPage): PageParams | undefined =>
      lastPage.pageInfo.hasNextPage && lastPage?.pageInfo?.endCursor
        ? { after: lastPage?.pageInfo?.endCursor }
        : undefined, // Return value undefined to indicate that there is no page
    getPreviousPageParam: (lastPage): PageParams | undefined =>
      lastPage.pageInfo.hasPreviousPage && lastPage?.pageInfo?.startCursor
        ? { before: lastPage?.pageInfo?.startCursor }
        : undefined,
    refetchInterval,
    refetchOnWindowFocus: false,
    enabled: _.isFunction(enabled) ? enabled({ search: searchQuery }) : enabled,
  })
  const {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage: hasRemoteNextPage,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    status,
    isLoading,
  } = query

  const entries = data?.pages?.[currentPage]?.data

  const hasNextPage = useMemo(() => {
    const dataPageLength = data?.pages?.length
    if (dataPageLength && currentPage + 1 < dataPageLength) {
      return true // Has next page cached locally
    }
    return hasRemoteNextPage
  }, [currentPage, data, hasRemoteNextPage])

  const hasPreviousPage = useMemo(() => {
    return currentPage > 0
  }, [currentPage])

  // If there is no next page, then getNextPage is null
  const getNextPage = useMemo(
    () =>
      hasNextPage
        ? async () => {
            // If the next page is not cached, then fetch it from the server
            const dataPageLength = data?.pages?.length
            if (dataPageLength && currentPage + 1 >= dataPageLength) {
              await fetchNextPage()
            }

            setCurrentPage(currentPage + 1)
            scrollToTop()
          }
        : null,
    [fetchNextPage, currentPage, data, hasNextPage],
  )

  // If there is no previous page, then getPreviousPage is null
  const getPreviousPage = useMemo(
    () =>
      hasPreviousPage
        ? () => {
            setCurrentPage(Math.max(currentPage - 1, 0))
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            scrollToTop()
          }
        : null,
    [currentPage, hasPreviousPage],
  )

  const allEntries = useMemo(() => {
    return data?.pages.flatMap((page) => {
      return page.data
    })
  }, [data])

  return {
    entries,
    allEntries,
    isEntriesLoading: isLoading,
    fetchEntriesStatus: status,
    isFetchingEntries: isFetching,
    isFetchingEntriesNextPage: isFetchingNextPage,
    isFetchingEntriesPreviousPage: isFetchingPreviousPage,
    fetchEntriesError: error,
    fetchEntriesCurrentSearchQuery: searchQuery,
    getNextPageOfEntries: getNextPage,
    getPreviousPageOfEntries: getPreviousPage,
    refreshFetchEntries: refresh,
    updateFetchEntriesSearchQuery: updateSearch,
    refetchEntries: refetch,
  }
}
