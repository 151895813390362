import { Icon } from '@chakra-ui/icons'
import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'
import { BiX } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import { NewCampaignFormCard } from '../components/NewCampaignFormCard'
import { useCreateCampaign } from '../hooks/useCreateCampaign'
import { CreateCampaignFormState } from '../validators/CreateCampaignFormState'

import { routes } from '~constants/routes'
import { getApiErrorMessage, isApiError } from '~lib/helpers/api'

export const CampaignCreationPage = () => {
  const { createCampaign } = useCreateCampaign()
  const navigate = useNavigate()
  const toast = useToast()

  const onCancelClick = () => {
    navigate(-1)
  }

  const onSubmit = async (data: CreateCampaignFormState) => {
    // split locationNamesString into an array of location names
    const locationNamesArray = data.locationNames.split('\n')

    // filter out empty strings
    const nonEmptyLocationNamesArray = locationNamesArray.filter(
      (locationName) => locationName !== '',
    )

    // trim location names
    const trimmedLocationNamesArray = nonEmptyLocationNamesArray.map((name) =>
      name.trim(),
    )

    // trim product names
    const trimmedProductNames = data.productNames.map((productName) =>
      productName.trim(),
    )

    try {
      await createCampaign({
        ...data,
        products: trimmedProductNames.map((name) => {
          return {
            name,
            metadata: {},
          }
        }),
        status: 'inactive',
        metadata: {},
        // convert the isWalkIn string value into a boolean value
        isWalkIn: data.isWalkIn === 'true' ? true : false,
        locations: trimmedLocationNamesArray.map((trimmedLocationName) => {
          return {
            name: trimmedLocationName,
            metadata: {},
          }
        }),
      })

      toast({
        title: 'Campaign created successfully!',
        status: 'success',
        description: 'Navigate to the Campaign to start setting it up.',
      })
      navigate(routes.index)
    } catch (e) {
      if (isApiError(e)) {
        toast({
          title: 'Error creating Campaign',
          status: 'error',
          description: getApiErrorMessage(e),
        })
      } else {
        toast({
          title: 'Error creating Campaign',
          status: 'error',
          description: 'An unknown error occurred',
        })
      }
    }
  }

  return (
    <VStack
      position="relative"
      flexGrow={1}
      width="100%"
      minH="100vh"
      alignItems="stretch"
    >
      <VStack
        minH="100vh"
        align="stretch"
        boxSizing="content-box"
        spacing={0} // Not sure why this is required to override the 0.5rem margin
      >
        <Box
          pt={12}
          pb={7}
          px={18}
          background="white"
          display="flex"
          justifyContent="center"
        >
          <HStack
            justifyContent="space-between"
            position="relative"
            w="full"
            maxW="container.xl"
          >
            <Text textStyle="h2">Create a new campaign</Text>
            <Button
              p={2}
              variant="clear"
              color="base.content.strong"
              onClick={onCancelClick}
            >
              <Icon as={BiX} fontSize="1.5rem" w={8} h={8} />
            </Button>
          </HStack>
        </Box>
        <NewCampaignFormCard onSubmit={onSubmit} />
      </VStack>
    </VStack>
  )
}
