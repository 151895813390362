import { Box, HStack, Text } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'
import { Table as ReactTable } from '@tanstack/react-table'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

type ControlledPaginationNavigationProps = {
  table: ReactTable<any>
}

// Pagination controlled by react-table
export const ControlledPaginationNavigation = ({
  table,
}: ControlledPaginationNavigationProps) => {
  const pageSize = table.getState().pagination.pageSize
  const pageNumber = table.getState().pagination.pageIndex
  const count = table.getFilteredRowModel().rows.length

  return (
    <Box>
      <HStack justifyContent="flex-end">
        <Text>
          Showing <Text as="b">{1 + pageSize * pageNumber}</Text> to{' '}
          <Text as="b">{pageSize + pageSize * pageNumber}</Text> of{' '}
          <Text as="b">{count}</Text> results
        </Text>
        <IconButton
          icon={<HiChevronLeft />}
          isDisabled={!table.getCanPreviousPage()}
          onClick={table.previousPage}
          color="neutral.700"
          variant="link"
          aria-label="previous page"
        />
        <IconButton
          icon={<HiChevronRight />}
          isDisabled={!table.getCanNextPage()}
          onClick={table.nextPage}
          color="neutral.700"
          variant="link"
          aria-label="next page"
        />
      </HStack>
    </Box>
  )
}
