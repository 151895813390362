import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import { useCampaignStatistics } from '~hooks/useCampaignStatistics'

type AllocationBarChartData = {
  completed: number
  remaining: number
}
interface AllocationBarChartProps {
  data: AllocationBarChartData[]
}

interface AllocationGraphLegendProps {
  colourHexCode: string
  description: string
  value: number
  isLoaded: boolean
  borderColor?: string
}

interface AllocationBreakdownProps {
  allocationsCompletedCount: number
  allocationsLeftCount: number
  totalAllocationCount: number
  isLoaded: boolean
}

interface AllocationBreakdownSectionProps {
  campaignId: string
}

const AllocationBarChart = ({ data }: AllocationBarChartProps) => {
  return (
    <ResponsiveContainer minWidth="100%" height={41}>
      <BarChart
        barCategoryGap="0%"
        layout="vertical"
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          type="number"
          hide
          allowDataOverflow
          // Specifies the domain of the axis and what values to
          // be shown of the barchart.
          domain={['dataMin', 'dataMax']}
        />
        <YAxis
          type="category"
          hide
          allowDataOverflow
          // Specifies the domain of the axis and what values to
          // be shown of the barchart.
          domain={['dataMin', 'dataMax']}
        />
        <Bar dataKey="completed" stackId="a" fill="#6F6FC7" />
        <Bar dataKey="remaining" stackId="a" fill="#CBCBF2" />
      </BarChart>
    </ResponsiveContainer>
  )
}

const AllocationGraphLegend = ({
  colourHexCode,
  borderColor,
  description,
  value,
  isLoaded,
}: AllocationGraphLegendProps) => {
  return (
    <HStack width="100%">
      <Box
        width="12px"
        height="12px"
        bg={colourHexCode}
        border={borderColor ? `1px solid ${borderColor}` : '0px'}
      />
      <Text textStyle="body-2">{description}</Text>
      <Spacer />
      <Skeleton
        isLoaded={isLoaded}
        startColor="grey.100"
        endColor="grey.50"
        width="4rem"
      >
        <Text textStyle="body-2">{Intl.NumberFormat('en').format(value)}</Text>
      </Skeleton>
    </HStack>
  )
}

const AllocationBreakdown = ({
  allocationsCompletedCount,
  allocationsLeftCount,
  totalAllocationCount,
  isLoaded,
}: AllocationBreakdownProps) => {
  const isAllocationDataAvailable = totalAllocationCount > 0

  const data: AllocationBarChartData[] = [
    {
      completed: allocationsCompletedCount,
      // Set remaining allocation count to be 1 if there is no allocation
      // data so that the barchart is still shown.
      remaining: isAllocationDataAvailable ? allocationsLeftCount : 1,
    },
  ]

  const completedAllocatedPercentage = isAllocationDataAvailable
    ? allocationsCompletedCount / totalAllocationCount
    : 0

  // Formatted to 2 decimal places.
  const completedAllocatedPercentageFormatted = Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(completedAllocatedPercentage)

  return (
    <VStack alignItems="flex-start" width="33rem">
      <HStack justifyContent="space-between" width="100%">
        <Text textStyle="subhead-1" color="base.content.strong" mr="auto">
          Percentage of allocations completed
        </Text>
        <Tooltip
          label={
            <Text textStyle="body-2">
              This is the percentage of allocations completed compared to total
              allocations.
            </Text>
          }
          placement="top"
          hasArrow
        >
          <InfoOutlineIcon />
        </Tooltip>
        <Spacer />
        <Skeleton isLoaded={isLoaded} startColor="grey.100" endColor="grey.50">
          <Text textStyle="subhead-1" color="base.content.strong">
            {completedAllocatedPercentageFormatted}
          </Text>
        </Skeleton>
      </HStack>

      {isLoaded ? (
        <AllocationBarChart data={data} />
      ) : (
        <Skeleton
          startColor="grey.100"
          endColor="grey.50"
          height="41px"
          width="100%"
        />
      )}

      <AllocationGraphLegend
        colourHexCode="#6F6FC7"
        description="Allocations completed"
        value={allocationsCompletedCount}
        isLoaded={isLoaded}
      />
      <AllocationGraphLegend
        colourHexCode="#CBCBF2"
        description="Allocations left"
        value={allocationsLeftCount}
        isLoaded={isLoaded}
      />
      <AllocationGraphLegend
        colourHexCode="#FFFFFF"
        borderColor="#BFC2C8"
        description="Total allocations"
        value={totalAllocationCount}
        isLoaded={isLoaded}
      />
    </VStack>
  )
}

export const AllocationBreakdownSection = ({
  campaignId,
}: AllocationBreakdownSectionProps) => {
  const { campaignStatistics, isFetching } = useCampaignStatistics(campaignId)

  const totalAllocationCount = campaignStatistics?.total_allocation_count ?? 0
  const totalDistributionCount =
    campaignStatistics?.total_distribution_count ?? 0
  const appealDistributionCount =
    campaignStatistics?.total_appeal_distribution_count ?? 0
  const allocationsCompletedCount =
    totalDistributionCount - appealDistributionCount
  const allocationsLeftCount = totalAllocationCount - allocationsCompletedCount

  return (
    <Box bg="white" borderRadius="4px">
      <Flex px={8} pt={8} mb={6}>
        <Text textStyle="h6" color="base.content.strong">
          Breakdown of Allocations
        </Text>
      </Flex>
      <Flex justifyContent="center" mb="3rem">
        <AllocationBreakdown
          allocationsCompletedCount={allocationsCompletedCount}
          allocationsLeftCount={allocationsLeftCount}
          totalAllocationCount={totalAllocationCount}
          isLoaded={!isFetching}
        />
      </Flex>
    </Box>
  )
}
