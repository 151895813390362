import { StreamParser } from '@json2csv/plainjs'
import { useToast } from '@opengovsg/design-system-react'
import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { useRef, useState } from 'react'
import { JsonObject } from 'type-fest'

import { AllocatedProductResponseDto } from '~shared/types/allocation.dto'
import {
  DistributedProductDto,
  ListDistributionPaginatedResponseDto,
} from '~shared/types/distribution.dto'
import { formatAsAddressString } from '~shared/utils/address'

import { CampaignView } from '~/types/campaign'

import { ApiService } from '~lib/helpers/api'
import { reprintDateTime } from '~utils/date'
import { exportCsv, ReportType } from '~utils/export'

const NUM_ROWS_PER_CALL = 10000 // Number of rows fetched per API call
const MAX_TRIES = 3 // Total 3 tries

type DistributionExportView = {
  id: string
  identifier?: string
  uniqueStringIdentifier?: string
  address?: string
  postalCode?: string
  block?: string
  floor?: string
  unit?: string
  hasAllocation: boolean
  allocatedProducts: Record<string, number>
  allocationMetadata: Record<string, unknown>
  distributedProducts: Record<string, number>
  distributorIdentifier: string
  distributorName: string
  locationName: string
  revision: number
  textRemark: string
  imageRemarks: string[]
  distributionMetadata: Record<string, unknown>
  createdAt: string
  updatedAt: string
}

export const useExportDistributions = (campaign: CampaignView | undefined) => {
  const toast = useToast()
  const [exporting, setExporting] = useState(false)
  const [hasErrorExporting, setHasErrorExporting] = useState(false)

  const abortController = useRef<AbortController>(new AbortController())

  const exportDistributions = async () => {
    if (!campaign) {
      throw new Error('Campaign is undefined')
    }

    setExporting(true)

    // Perform a recursive query on the paginated API to fetch all distributions
    const distributionsToExport: DistributionExportView[] = []
    let hasNextPage
    let after: string | undefined

    // Also adding a retry mechanism in case one of calls fail
    let numTries = 0

    const allocationMetadataFields = new Set<string>()

    do {
      try {
        numTries += 1
        const response =
          await ApiService.get<ListDistributionPaginatedResponseDto>(
            `/campaigns/${campaign.id}/distributions`,
            {
              params: { limit: NUM_ROWS_PER_CALL, after },
              signal: abortController.current.signal,
            },
          )

        const { data: snakecaseData, page_info: snakecasePageInfo } =
          response.data

        // Only camelcase the immediate attribute keys. Do not
        // recursively camelcase the nested object keys because it
        // will camelcase the the metadata keys and display it inaccurately
        // in the distribution report.
        const data = camelcaseKeys(snakecaseData, { deep: false })
        const pageInfo = camelcaseKeys(snakecasePageInfo, { deep: false })

        data.forEach((d) => {
          const distributedProducts = getProductNameToQuantityMapping(
            campaign,
            d.products,
          )
          const allocatedProducts = getProductNameToQuantityMapping(
            campaign,
            d.allocation?.products,
          )
          const textRemark =
            d.remarks.find((r) => r.type === 'text')?.body ?? ''
          const imageRemarks = d.remarks
            .filter((r) => r.type === 'image')
            .map((r) => r.body)

          extractMetadataFields(
            d.allocation?.metadata ?? {},
            allocationMetadataFields,
          )

          const distributionToExport: DistributionExportView = {
            id: d.id,
            ...(campaign.identifierType === 'nric' && {
              identifier: d.identifier || '',
            }),
            ...(campaign.identifierType === 'unique_string' && {
              uniqueStringIdentifier: d.uniqueStringIdentifier || '',
            }),
            ...(campaign.identifierType === 'address' && {
              address: formatAsAddressString({
                postalCode: d.postalCode,
                floor: d.floor,
                unit: d.unit,
                streetName: d.streetName,
              }),
            }),
            ...(campaign.identifierType === 'address' && {
              postalCode: d.postalCode || '',
            }),
            ...(campaign.identifierType === 'address' && {
              block: d.block || '',
            }),
            ...(campaign.identifierType === 'address' && {
              floor: d.floor || '',
            }),
            ...(campaign.identifierType === 'address' && {
              unit: d.unit || '',
            }),
            ...(campaign.identifierType === 'address' && {
              streetName: d.streetName || '',
            }),
            hasAllocation: d.allocation !== null,
            allocatedProducts,
            allocationMetadata: d.allocation?.metadata ?? {},
            distributedProducts,
            distributorIdentifier: d.distributorIdentifier,
            distributorName: d.distributorName,
            locationName: d.locationName,
            textRemark,
            imageRemarks,
            revision: d.revision,
            distributionMetadata: d.metadata,
            createdAt: reprintDateTime(d.createdAt),
            updatedAt: reprintDateTime(d.updatedAt),
          }
          distributionsToExport.push(distributionToExport)
        })

        hasNextPage = pageInfo.hasNextPage
        after = pageInfo.endCursor ?? undefined

        // Reset the number of tries on success
        numTries = 0
      } catch (e) {
        // this checks if the error is caused by an abort
        // instead of an error sent from the server
        if (axios.isCancel(e)) {
          setExporting(false)
          // a single abortController instance can only abort once and it will
          // retain its abort signal even when .abort() is called multiple times.
          // Hence after every cancellation, update the ref to reference a new
          // AbortController instance
          abortController.current = new AbortController()
          return
        }

        if (numTries < MAX_TRIES) {
          hasNextPage = true // to guarantee retry
          continue
        }
        // Terminate the export report process
        setExporting(false)
        setHasErrorExporting(true)
        return
      }
    } while (hasNextPage)

    if (distributionsToExport.length === 0) {
      toast({
        status: 'warning',
        description: 'There are no distributions to export.',
      })
      setExporting(false)
      setHasErrorExporting(false)
      return
    }

    const opts = {
      fields: generateOrderedColumns(
        campaign,
        Array.from(allocationMetadataFields),
      ),
    }
    const asyncOpts = { objectMode: true }
    const parser = new StreamParser(opts, asyncOpts)
    let csv = ''
    parser.onData = (chunk) => (csv += chunk.toString())
    parser.onEnd = () => {
      const blob = new Blob([csv], { type: 'text/csv' })

      exportCsv(ReportType.Distribution, blob, campaign.name)

      setExporting(false)
      setHasErrorExporting(false)
    }
    parser.onError = (err) => console.error(err)
    distributionsToExport.forEach((record) => parser.pushLine(record))

    parser.end()
    abortController.current = new AbortController()
  }
  return {
    exporting,
    exportDistributions,
    abortController,
    hasErrorExporting,
  }
}

// Order columns to match desired order in the CSV
function generateOrderedColumns(
  campaign: CampaignView,
  allocationMetadataFields: string[],
) {
  // Conditionally including identifier columns this way because spreading
  // in the final object causes some type errors in the StreamParser opts
  const identifierColumns = []
  switch (campaign.identifierType) {
    case 'nric':
      identifierColumns.push({
        label: 'Identifier',
        value: 'identifier',
      })
      break
    case 'unique_string':
      identifierColumns.push({
        label: 'Unique Identifier',
        value: 'uniqueStringIdentifier',
      })
      break
    case 'address':
      identifierColumns.push(
        {
          label: 'Address',
          value: 'address',
        },
        {
          label: 'Postal Code',
          value: 'postalCode',
        },
        {
          label: 'Block',
          value: 'block',
        },
        {
          label: 'Floor',
          value: 'floor',
        },
        {
          label: 'Unit',
          value: 'unit',
        },
        {
          label: 'Street Name',
          value: 'streetName',
        },
      )
  }

  return [
    {
      label: 'ID',
      value: 'id',
    },
    ...identifierColumns,
    {
      label: 'Has Allocation',
      value: (record: DistributionExportView) => {
        return record.hasAllocation ? 'Yes' : 'No'
      },
    },
    ...campaign.products.map((product) => {
      return {
        label: `Allocated Qty - ${product.name}`,
        value: (record: DistributionExportView) => {
          const qty = record.allocatedProducts[product.id]
          if (qty === undefined || qty === 0) {
            return '-'
          }
          return qty
        },
      }
    }),
    ...allocationMetadataFields.map((field) => {
      return {
        label: `Allocation Metadata - ${field}`,
        value: (record: DistributionExportView) => {
          return record.allocationMetadata[field] ?? ''
        },
      }
    }),
    ...campaign.products.map((product) => {
      return {
        label: `Distributed Qty - ${product.name}`,
        value: (record: DistributionExportView) => {
          return record.distributedProducts[product.id] ?? 0
        },
      }
    }),
    {
      label: 'Distributor Identifier',
      value: 'distributorIdentifier',
    },
    {
      label: 'Distributor Name',
      value: 'distributorName',
    },
    {
      label: 'Location Name',
      value: 'locationName',
    },
    {
      label: 'Revision',
      value: 'revision',
    },
    {
      label: 'Remarks',
      value: 'textRemark',
    },
    {
      label: 'Image Remarks',
      value: (record: DistributionExportView) => {
        return record.imageRemarks.join(', ')
      },
    },
    {
      label: 'Created At',
      value: 'createdAt',
    },
    {
      label: 'Updated At',
      value: 'updatedAt',
    },
  ]
}

export function getProductNameToQuantityMapping(
  campaign: CampaignView,
  productQuantityData:
    | DistributedProductDto[]
    | AllocatedProductResponseDto[]
    | undefined,
): Record<string, number> {
  if (!productQuantityData) {
    return campaign.products.reduce((acc, product) => {
      acc[product.id] = 0
      return acc
    }, {} as Record<string, number>)
  }

  const result: Record<string, number> = {}
  for (const product of campaign.products) {
    const data = productQuantityData.find((d) => d.id === product.id)

    let qty: number
    if (campaign.hasQuantity) {
      qty = data?.quantity ?? 0
    } else {
      qty = data ? 1 : 0
    }

    result[product.id] = qty
  }
  return result
}

export function extractMetadataFields(
  metadata: JsonObject,
  fields: Set<string>,
) {
  for (const key of Object.keys(metadata)) {
    fields.add(key)
  }
}
