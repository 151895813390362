export const routes = {
  index: '/',
  createCampaign: '/create-campaign',
  login: '/login',
  health: '/health',
  allocation: '/:campaignId/allocations',
  distribution: '/:campaignId/distributions',
  location: '/:campaignId/locations',
  distributor: '/:campaignId/distributors',
  settings: '/:campaignId/settings',
  statistics: '/:campaignId/statistics',
} as const
