import { DeleteLocationResponseDto } from '~shared/types/location.dto'

import { ApiService } from '~lib/helpers/api'

export const deleteLocationById = async (
  campaignId: string,
  locationId: string,
) => {
  const res = await ApiService.delete<DeleteLocationResponseDto>(
    `/campaigns/${campaignId}/locations/${locationId}`,
  )

  return res.data
}
