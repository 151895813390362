import type { WhoAmIResponseDto } from '~shared/types/auth.dto'

import { ApiService } from '~lib/helpers/api'

/**
 * Fetches the user from the server using the current session cookie.
 *
 * @returns the logged in user if session is valid, will throw 401 error if not.
 */
export const fetchUser = async () => {
  const response = await ApiService.get<WhoAmIResponseDto>('/auth/whoami')
  return response.data
}
