import { getTimestampForCsvReport } from './date'

export enum ReportType {
  Allocation = 'allocation',
  Distribution = 'distribution',
}

export function exportCsv(
  reportType: ReportType,
  blob: Blob,
  campaignName: string,
) {
  const link = document.createElement('a')

  // Checks if the download attribute is supported by the link element.
  // The download attribute allows the file to be downloaded when the link
  // is clicked.
  if (link.download !== undefined) {
    const url = window.URL.createObjectURL(blob) // creates the URL for the given blob object
    link.setAttribute('href', url)
    const snakeCaseCampaignName = campaignName.replace(/ /g, '_')
    const timestamp = getTimestampForCsvReport()

    const fileName = `${
      reportType === ReportType.Allocation ? 'allocation' : 'distribution'
    }_${snakeCaseCampaignName}_${timestamp}`

    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // clean up to prevent any memory leaks
  }
}
