import { QuestionIcon } from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Button } from '@opengovsg/design-system-react'
import { Row } from '@tanstack/react-table'
import { IsNotEmpty } from 'class-validator'
import _ from 'lodash'
import { useForm } from 'react-hook-form'

import { LocationView } from '~/types/location'

type UpdateLocationModalProps = {
  isOpen: boolean
  onClose: () => void
  confirmUpdate: (data: unknown) => Promise<void>
  rowToUpdate: unknown
}

class UpdateLocationFormState {
  @IsNotEmpty({
    message: 'Location name must not be empty',
  })
  name: string
}

export const UpdateLocationModal = ({
  isOpen,
  onClose,
  confirmUpdate,
  rowToUpdate: rowToUpdateRaw,
}: UpdateLocationModalProps) => {
  const rowToUpdate = rowToUpdateRaw as Row<LocationView>

  // Render nothing if rowToUpdate not provided
  if (_.isEmpty(rowToUpdate)) return <></>

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateLocationFormState>({
    resolver: classValidatorResolver(UpdateLocationFormState),
    defaultValues: {
      name: rowToUpdate.original.name,
    },
  })

  const onFormSubmit = async (data: UpdateLocationFormState) => {
    await confirmUpdate(data)
  }

  const closeModal = () => {
    reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Location Name</FormLabel>
                <Input type="text" id="name" {...control.register('name')} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>
                  Location Code
                  <Tooltip
                    label="A location code is unique to each location, and cannot be altered."
                    placement="right"
                    hasArrow
                  >
                    <QuestionIcon
                      fontSize="12px"
                      ml={2}
                      mb="2px" // 2px to align it vertically with the text
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  type="text"
                  id="shortCode"
                  value={rowToUpdate.original.shortCode}
                  disabled={true}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack flexGrow={1} justifyContent="flex-end">
              <Button
                onClick={onClose}
                colorScheme="black"
                variant="clear"
                px={4}
                mr={2}
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                Save changes
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
