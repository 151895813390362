import { Badge } from '@opengovsg/design-system-react'

type BadgeStatus = 'success' | 'failure' | 'warning'
interface StatusBadgeProps {
  status: BadgeStatus
  text: string
}

const getStyle = (status: BadgeStatus) => {
  switch (status) {
    case 'success':
      return {
        bg: 'green.100',
        color: 'green.600',
      }
    case 'failure':
      return {
        bg: 'red.100',
        color: 'red.600',
      }
    case 'warning':
      return {
        bg: 'yellow.100',
        color: 'yellow.600',
      }
  }
}

export const StatusBadge = ({ status, text }: StatusBadgeProps) => {
  return (
    // FIXME: Fetch styles via useStyleConfig
    // https://chakra-ui.com/docs/styled-system/component-style#styling-single-part-components
    <Badge
      variant="subtle"
      sx={getStyle(status)}
      size="sm"
      px={2}
      py="2px"
      borderRadius="4px"
    >
      {text}
    </Badge>
  )
}
