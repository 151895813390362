import { CampaignOnboardingBulkUploadModal } from '~components/CampaignOnboarding/CampaignOnboardingBulkUploadModal'
import { useDownloadTemplateCsv } from '~features/campaign/hooks/useDownloadTemplateCsv'
import { useUploadCampaignOnboardingCsv } from '~features/campaign/hooks/useUploadCampaignOnboardingCsv'

export type BulkUploadAllocationModalProps = {
  campaignId: string
  isOpen: boolean
  showDataOverrideWarning: boolean
  onOpen: () => void
  onClose: () => void
}

const MODAL_TITLE = 'Upload multiple allocations'
const MODAL_DESCRIPTION = 'You can upload your allocations with a CSV file. '
const MODAL_INSTRUCTIONS_HEADER = 'Fill in CSV template with allocation details'
const MODAL_INSTRUCTIONS_CONTENT = `You will need to enter your recipients' identifier, items they will receive, and the locations they will collect these items from.`
const MODAL_DATA_OVERRIDE_WARNING_DESCRIPTION =
  'Uploading a CSV file will override your existing allocations.'

export const BulkUploadAllocationModal = ({
  campaignId,
  isOpen,
  showDataOverrideWarning,
  onClose,
}: BulkUploadAllocationModalProps) => {
  const { downloadTemplateCsv } = useDownloadTemplateCsv()
  const { uploadCampaignOnboardingCsv, isUploadingCampaignOnboardingCsv } =
    useUploadCampaignOnboardingCsv()

  return (
    <CampaignOnboardingBulkUploadModal
      title={MODAL_TITLE}
      description={MODAL_DESCRIPTION}
      instructionsHeader={MODAL_INSTRUCTIONS_HEADER}
      instructionsContent={MODAL_INSTRUCTIONS_CONTENT}
      showDataOverrideWarning={showDataOverrideWarning}
      dataOverrideWarningAlertDescription={
        MODAL_DATA_OVERRIDE_WARNING_DESCRIPTION
      }
      isOpen={isOpen}
      onClose={onClose}
      onUpload={(file: File) =>
        uploadCampaignOnboardingCsv({
          file,
          campaignId,
          type: 'upload_allocations',
        })
      }
      isUploading={isUploadingCampaignOnboardingCsv}
      downloadTemplateCsv={() =>
        downloadTemplateCsv({ campaignId, type: 'upload_allocations' })
      }
    />
  )
}
