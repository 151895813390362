import { CheckIcon } from '@chakra-ui/icons'
import { Box, Text } from '@chakra-ui/react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'

import { AllocationView } from '~/types/allocation'
import { CampaignView } from '~/types/campaign'

import DataTable from '~components/DataTable'
import { NegativeText } from '~components/NegativeText/NegativeText'

interface AllocationsTableProps {
  data: AllocationView[]
  campaign: CampaignView | undefined
}

const columnHelper = createColumnHelper<AllocationView>()

export const AllocationsTable = ({ data, campaign }: AllocationsTableProps) => {
  const columns = [
    columnHelper.accessor('identifier', {
      header: () => <Text textStyle="caption-1">{'id'.toUpperCase()}</Text>,
    }),
    columnHelper.accessor('uniqueStringIdentifier', {
      header: () => (
        <Text textStyle="caption-1">{'unique identifier'.toUpperCase()}</Text>
      ),
    }),
    columnHelper.accessor('postalCode', {
      header: () => (
        <Text textStyle="caption-1">{'postal code'.toUpperCase()}</Text>
      ),
    }),
    columnHelper.accessor('block', {
      header: () => <Text textStyle="caption-1">{'block'.toUpperCase()}</Text>,
    }),
    columnHelper.accessor('floor', {
      header: () => <Text textStyle="caption-1">{'floor'.toUpperCase()}</Text>,
    }),
    columnHelper.accessor('unit', {
      header: () => <Text textStyle="caption-1">{'unit'.toUpperCase()}</Text>,
    }),
    columnHelper.accessor('locationName', {
      header: () => (
        <Box textAlign="center" textStyle="caption-1">
          {'location'.toUpperCase()}
        </Box>
      ),
      cell: (info) => {
        return _.isEmpty(info.getValue()) ? (
          <Box textAlign="center">
            <NegativeText>-</NegativeText>
          </Box>
        ) : (
          <Box textAlign="center">{info.getValue()}</Box>
        )
      },
    }),
    ...(campaign?.products.map((product) => {
      return columnHelper.accessor('products', {
        header: () => (
          <Box textAlign="center" textStyle="caption-1">
            {' '}
            {product.name.toUpperCase()}{' '}
          </Box>
        ),
        cell: (info) => {
          const allocationProduct = info
            .getValue()
            .find((ap) => ap.id === product.id)
          const quantityAllocated = campaign.hasQuantity
            ? allocationProduct?.quantity
            : allocationProduct && <CheckIcon />
          return (
            <Box textAlign="center">
              {quantityAllocated ?? <NegativeText>-</NegativeText>}
            </Box>
          )
        },
        id: product.id,
      })
    }) ?? []),
    columnHelper.accessor('updatedAt', {
      header: () => (
        <Text textStyle="caption-1">{'last updated'.toUpperCase()}</Text>
      ),
    }),
  ]

  const table = useReactTable({
    columns,
    data,
    // From docs (https://tanstack.com/table/v8/docs/api/features/pagination#pagecount), if row is not known `-1` can be set.
    pageCount: -1,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    enableSortingRemoval: false,
    manualSorting: true,
    manualPagination: true,
    enableColumnFilters: false,
    manualFiltering: true,
    enableSorting: false,
    initialState: {
      columnVisibility: {
        identifier: campaign?.identifierType === 'nric',
        uniqueStringIdentifier: campaign?.identifierType === 'unique_string',
        postalCode: campaign?.identifierType === 'address',
        block: campaign?.identifierType === 'address',
        floor: campaign?.identifierType === 'address',
        unit: campaign?.identifierType === 'address',
      },
    },
    state: {
      sorting: [],
    },
  })

  return (
    <Box>
      {data.length > 0 ? (
        <DataTable instance={table} borderColor="base.divider.medium" />
      ) : (
        <Text>There are no allocations yet.</Text>
      )}
    </Box>
  )
}
