import { Box, Skeleton, Table, Td, Th, Tr } from '@chakra-ui/react'
import _ from 'lodash'

export const TableSkeleton = () => {
  return (
    <Table>
      <thead>
        <Tr>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>
        </Tr>
      </thead>
      <tbody>
        <SkeletonRow width="75px" />
        <SkeletonRow width="125px" />
        <SkeletonRow width="50px" />
        <SkeletonRow width="100px" />
        <SkeletonRow width="75px" />
      </tbody>
    </Table>
  )
}

const SkeletonRow = ({ width }: { width: string }) => {
  return (
    <Box as="tr">
      {_.range(4).map((i) => (
        <Td>
          <Skeleton
            height="10px"
            w={width}
            my={4}
            startColor="grey.100"
            endColor="grey.50"
            key={i}
          />
        </Td>
      ))}
    </Box>
  )
}
