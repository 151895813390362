import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import {
  IconButton,
  ModalCloseButton,
  useToast,
} from '@opengovsg/design-system-react'
import { Controller, useForm } from 'react-hook-form'
import { HiOutlineTrash } from 'react-icons/hi'

import { useAddCampaignAdmin } from '../hooks/useAddCampaignAdmin'
import { useCampaignAdmins } from '../hooks/useCampaignAdmins'
import { useDeleteCampaignAdmin } from '../hooks/useDeleteCampaignAdmin'
import { ManageCampaignAdminFormState } from '../validators/ManageCampaignAdminFormState'

import { useAuth } from '~lib/auth'
import { getApiErrorMessage, isApiError } from '~lib/helpers/api'

export const ManageCampaignAdminModal = ({
  campaignId,
  isOpen,
  onClose,
}: {
  campaignId: string
  isOpen: boolean
  onClose: () => void
}): JSX.Element => {
  const { campaignAdmins } = useCampaignAdmins({
    campaignId,
  })
  const { addCampaignUser } = useAddCampaignAdmin({
    campaignId,
  })
  const { deleteCampaignUser } = useDeleteCampaignAdmin({ campaignId })

  // Get current user
  const { user } = useAuth()

  const toast = useToast()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ManageCampaignAdminFormState>({
    resolver: classValidatorResolver(ManageCampaignAdminFormState),
    defaultValues: {
      email: '', // For controlled components, default values required to reset form
    },
  })

  const onFormSubmit = async (data: ManageCampaignAdminFormState) => {
    try {
      await addCampaignUser({
        email: data.email,
        type: 'admin',
        metadata: {},
      })

      toast({
        title: `${data.email} has been added as a collaborator.`,
        status: 'success',
      })

      reset()
    } catch (e) {
      if (isApiError(e)) {
        toast({
          title: `Error adding ${data.email} as a collaborator.`,
          status: 'error',
          description: getApiErrorMessage(e),
        })
      } else {
        toast({
          title: `Error adding ${data.email} as a collaborator.`,
          description:
            'Please try again later. If the problem persists, please contact the DistributeSG team.',
          status: 'error',
        })
      }
    }
  }

  const handleOnDeleteCampaignCollaborator = async (
    campaignUserId: string,
    campaignCollaboratorEmail: string,
  ) => {
    try {
      await deleteCampaignUser(campaignUserId)

      toast({
        title: `${campaignCollaboratorEmail} has been removed as a collaborator.`,
        status: 'success',
      })
    } catch (e) {
      toast({
        title: `Error removing ${campaignCollaboratorEmail} as a collaborator.`,
        description:
          'Please try again later. If the problem persists, please contact the DistributeSG team.',
        status: 'error',
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text textStyle="h4">Manage Collaborators</Text>
        </ModalHeader>
        <ModalCloseButton onClick={() => reset()} />

        {/* Modal body */}
        <ModalBody pb={8}>
          <VStack alignItems="flex-start" mb={12}>
            <Text textStyle="subhead-1">
              Add collaborators to your campaign
            </Text>
            <Text textStyle="body-2">
              Your collaborators will receive the same admin rights as you.
            </Text>
            <HStack as="form" w="full" spacing={2} pt={4} alignItems="start">
              <FormControl isInvalid={!!errors.email} isRequired>
                <Controller
                  render={({ field }) => (
                    <Input placeholder="user@agency.gov.sg" {...field} />
                  )}
                  name="email"
                  control={control}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <Button
                isLoading={isSubmitting}
                onClick={handleSubmit(onFormSubmit)}
                type="submit"
                size="md"
              >
                Add Collaborator
              </Button>
            </HStack>
          </VStack>
          <Box width="full">
            {/* Render current admin separately */}
            <VStack alignItems="flex-start">
              <Divider />
              {/* Not sure why the hr has a margin-top: 0.5rem */}
              <HStack
                pt={4}
                pb={4}
                w="full"
                justifyContent="space-between"
                mt="0px !important"
              >
                <Text textStyle="body-1">
                  {user?.email}{' '}
                  <Text
                    as="span"
                    textStyle="body-1"
                    color="base.content.medium"
                  >
                    (you)
                  </Text>
                </Text>
              </HStack>
              <Divider mt="0px !important" />
            </VStack>
            {campaignAdmins?.map((admin) => {
              // Render all admins other than the current user
              if (admin.user.email === user?.email) return
              return (
                <VStack alignItems="flex-start">
                  {/* Not sure why the hr has a margin-top: 0.5rem */}
                  <HStack pt={2} pb={2} w="full" justifyContent="space-between">
                    <Text textStyle="body-1">{admin.user.email}</Text>
                    <Tooltip label={'Delete'} placement="top">
                      <IconButton
                        variant="clear"
                        color="interaction.critical.default"
                        icon={<HiOutlineTrash fontSize="1.25rem" />}
                        onClick={() =>
                          handleOnDeleteCampaignCollaborator(
                            admin.id.toString(),
                            // This should not happen because all admin users have
                            // an email associated to them.
                            admin.user.email ?? '',
                          )
                        }
                        aria-label="Delete campaign collaborator"
                      />
                    </Tooltip>
                  </HStack>
                  <Divider mt="0px !important" />
                </VStack>
              )
            })}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
