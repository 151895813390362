import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  HStack,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'

import { TableStatisticsRow } from './TableStatisticsRow'

import { useCampaignStatistics } from '~hooks/useCampaignStatistics'

interface LocationStatisticsProps {
  campaignId: string
  isWalkIn: boolean
}

export const LocationStatistics = ({
  campaignId,
  isWalkIn,
}: LocationStatisticsProps) => {
  const { campaignStatistics, isFetching } = useCampaignStatistics(campaignId)

  const locationStats = campaignStatistics?.distribution_stats_by_location ?? []

  const totalDistributionCount = locationStats.reduce(
    (sum, current) => sum + current.distribution_count,
    0,
  )
  const totalDistributionCountFormatted = Intl.NumberFormat('en').format(
    totalDistributionCount,
  )

  const locationStatsData = locationStats.map((locationStatsRow) => {
    return {
      locationName: locationStatsRow.location_name,
      allocated: locationStatsRow.allocation_count ?? 0,
      distributed: locationStatsRow.distribution_count,
    }
  })

  return (
    <Box bg="white" borderRadius="4px" width="50%">
      <VStack alignItems="stretch" mx={8} pt={8} mb={6}>
        <HStack>
          <Text textStyle="h6" color="base.content.strong">
            Total Distributions
          </Text>
          <Tooltip
            label={
              <Text textStyle="body-2">
                See the number of total distributions and a breakdown by
                locations. This includes appeals that have been made.
              </Text>
            }
            placement="top"
            hasArrow
          >
            <InfoOutlineIcon />
          </Tooltip>
          <Spacer />
          <Skeleton
            isLoaded={!isFetching}
            startColor="grey.100"
            endColor="grey.50"
          >
            <Text textStyle="h6" color="base.content.strong">
              {totalDistributionCountFormatted}
            </Text>
          </Skeleton>
        </HStack>
        <Divider my={2} />
        <HStack>
          <Text textStyle="subhead-2" width="18rem">
            Locations
          </Text>
          <Spacer />
          {!isWalkIn && (
            <Text textStyle="subhead-2" width="96px" textAlign="right">
              Allocated
            </Text>
          )}
          <Text textStyle="subhead-2" width="96px" textAlign="right">
            Distributed
          </Text>
        </HStack>
        <Skeleton
          isLoaded={!isFetching}
          startColor="grey.100"
          endColor="grey.50"
        >
          <VStack maxH="18rem" minH="18rem" overflowY="auto">
            {locationStatsData.map((data) => {
              return (
                <TableStatisticsRow
                  rowName={data.locationName}
                  allocated={data.allocated}
                  distributed={data.distributed}
                  // Location name is unique within
                  // each campaign due to user input constraint
                  key={data.locationName}
                  isWalkIn={isWalkIn}
                />
              )
            })}
          </VStack>
        </Skeleton>
      </VStack>
    </Box>
  )
}
