import { Box, Image, Text, VStack } from '@chakra-ui/react'

import { CampaignIdentifierType } from '~shared/constants/campaign'

import RecipientHousehold from '~/assets/preview/recipient_household.svg'
import RecipientIndividual from '~/assets/preview/recipient_individual.svg'
import RecipientUnique from '~/assets/preview/recipient_unique.svg'

const beneficiaryTypeToCardProps = {
  nric: {
    title: 'NRIC/FIN',
    imageUrl: RecipientIndividual,
    description: 'Recipients will be identified by their NRIC/FIN.',
  },
  address: {
    title: 'Registered Address',
    imageUrl: RecipientHousehold,
    description: 'Recipients will be identified by their registered addresses.',
  },
  unique_string: {
    title: 'Unique Identifier',
    imageUrl: RecipientUnique,
    description:
      'Recipients will be identified by the unique data that you provide.',
  },
} as const

export type BeneficiaryTypeSelectionCard = {
  beneficiaryType: CampaignIdentifierType
  isSelected: boolean
}

export const BeneficiaryTypeSelectionCard = ({
  beneficiaryType,
  isSelected,
}: BeneficiaryTypeSelectionCard) => {
  const beneficiaryCardProps = beneficiaryTypeToCardProps[beneficiaryType]

  return <BeneficiaryCard {...beneficiaryCardProps} isSelected={isSelected} />
}

const BeneficiaryCard = ({
  imageUrl,
  title,
  description,
  isSelected,
}: {
  imageUrl: string
  title: string
  description: string
  isSelected: boolean
}) => {
  return (
    <VStack
      h="full"
      alignItems="flex-start"
      borderWidth={isSelected ? '2px' : '1px'}
      borderColor={isSelected ? 'brand.primary.500' : 'grey.200'}
      backgroundColor={isSelected ? 'brand.primary.100' : 'white'}
      borderRadius="md"
      cursor="pointer"
      p={4}
    >
      <Box mb={2} mt={3}>
        <Image src={imageUrl} width="48px" />
      </Box>
      <Text textStyle="subhead-1">{title}</Text>

      <Box mb={3}>
        <Text textStyle="body-2">{description}</Text>
      </Box>
    </VStack>
  )
}
