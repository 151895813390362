import {
  Box,
  Divider,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'
import { useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { BiBell } from 'react-icons/bi'
import { FaCircleCheck, FaCircleXmark, FaClock } from 'react-icons/fa6'

import { CampaignOnboardingJobResponseDto } from '~shared/types/campaign-onboarding.dto'

import { CampaignOnboardingJobErrorModal } from './CampaignOnboardingJobErrorModal'

import { useCampaignOnboardingJobs } from '~features/campaign/hooks/useCampaignOnboardingJobs'
import { queryKeys } from '~lib/constants/query'
import { formatAsTimeFromNow } from '~lib/helpers/date'

type CampaignOnboardingJobView = CampaignOnboardingJobResponseDto

export const CampaignOnboardingJobMenu = ({
  campaignId,
}: {
  campaignId: string
}) => {
  const {
    isOpen: isCampaignOnboardingJobErrorModalOpen,
    onOpen: onCampaignOnboardingJobErrorModalOpen,
    onClose: onCampaignOnboardingJobErrorModalClose,
  } = useDisclosure()
  const [selectedJob, setSelectedJob] = useState<CampaignOnboardingJobView>()
  const { jobs, isLoadingJobs } = useCampaignOnboardingJobs(campaignId)
  const [shouldPollForUpdates, setShouldPollForUpdates] = useState(false)
  const [showJobNotificationDot, setShowJobNotificationDot] = useState(false)

  const queryCache = useQueryClient()

  const toast = useToast()

  // Get latest bulk upload job
  const latestJob = _.first(jobs)

  const handleSelectJob = (job: CampaignOnboardingJobView) => {
    if (job.status !== 'failed') return
    setSelectedJob(job)
    onCampaignOnboardingJobErrorModalOpen()
  }

  useEffect(() => {
    if (latestJob) {
      // If the first job status is pending or processing, it means
      // that the user has batch uploaded a csv file for either
      // Allocations, Distributors or Locations.
      // We won't show any notification for this and instead use a toast to
      // indicate that the job is still processing.
      if (latestJob.status === 'pending' || latestJob.status === 'processing') {
        setShouldPollForUpdates(true)
        return
      }

      // If the first job status is success or failed and the isNewBatchJob flag is
      // true, that means a recent pending or processing job has been processed.
      if (shouldPollForUpdates) {
        setShouldPollForUpdates(false)

        // If the job is successful after processing, we will show a
        // success toast and also invalidate the query cache to trigger
        // a refetch of data.
        if (latestJob.status === 'success') {
          setShowJobNotificationDot(true)

          let successfulToastDescription = ''

          const invalidateCache = async (job: CampaignOnboardingJobView) => {
            switch (job.type) {
              case 'upload_locations':
                successfulToastDescription = 'You have uploaded new locations.'

                await queryCache.invalidateQueries(
                  queryKeys.campaigns.detail(campaignId)._ctx.locations,
                )

                // Invalidate the query cache for the location count
                // to trigger a refersh of the updated location data count.
                await queryCache.invalidateQueries(
                  queryKeys.campaigns.detail(campaignId)._ctx.locationCount,
                )
                break
              case 'upload_allocations':
                successfulToastDescription =
                  'You have uploaded new allocations.'

                await queryCache.invalidateQueries(
                  queryKeys.campaigns.detail(campaignId)._ctx.allocations,
                )

                // Invalidate the query cache for the allocation count
                // to trigger a refresh of the updated allocation data count.
                await queryCache.invalidateQueries(
                  queryKeys.campaigns.detail(campaignId)._ctx.allocationsCount,
                )
                break
              case 'upload_distributors':
                successfulToastDescription =
                  'You have uploaded new distributors.'

                await queryCache.invalidateQueries(
                  queryKeys.campaigns.detail(campaignId)._ctx.distributors,
                )
                break
            }
          }

          toast({
            title: 'Upload successful.',
            description: successfulToastDescription,
            status: 'success',
          })

          void invalidateCache(latestJob)
        }

        // If the job failed after processing, we will show an
        // error toast.
        else if (latestJob.status === 'failed') {
          setShowJobNotificationDot(true)

          // Error description message includes a clickable
          // text which opens up the modal indicating the errors
          // in the bulk upload.
          const failureToastErrMsg = (
            <Text>
              We were unable to process your data. Click{' '}
              <Link
                onClick={() => {
                  // This opens up the error modal for the latest job
                  // which has failed.
                  handleSelectJob(latestJob)
                  // Set show job notification dot value
                  // as false because user will see the
                  // bulk job error modal by clicking this.
                  setShowJobNotificationDot(false)
                  toast.closeAll()
                }}
              >
                here
              </Link>{' '}
              to view the errors.
            </Text>
          )

          toast({
            title: 'Upload failed.',
            description: failureToastErrMsg,
            status: 'error',
          })
        }
      }
    }
  }, [JSON.stringify(latestJob)])

  return (
    <HStack
      align="center"
      spacing={8}
      flexGrow={1}
      flexBasis={0}
      visibility={jobs && jobs.length > 0 ? 'visible' : 'hidden'}
    >
      <Skeleton
        isLoaded={!isLoadingJobs}
        position="relative"
        startColor="grey.100"
        endColor="grey.50"
      >
        {/* Has job in progres indicator */}
        {showJobNotificationDot && (
          <Box
            position="absolute"
            top="11px"
            left="12px"
            width="10px"
            height="10px"
            zIndex={20}
            borderRadius="8px"
            backgroundColor="utility.feedback.critical"
          />
        )}
        <Menu
          closeOnBlur={isCampaignOnboardingJobErrorModalOpen ? false : true}
        >
          <MenuButton
            variant="clear"
            as={IconButton}
            icon={<BiBell fontSize="1.5rem" />}
            minW="inherit"
            color="interaction.links.neutral-default"
            p={0}
            border="0"
            _hover={{ backgroundColor: 'white' }}
            _active={{ backgroundColor: 'white' }}
            onClick={() => setShowJobNotificationDot(false)}
          />
          <MenuList overflowY="auto" maxHeight="500px" margin={0} p={0}>
            <VStack spacing={0}>
              {jobs?.map((job) => (
                <>
                  <Box onClick={() => handleSelectJob(job)}>
                    <JobDetailsCard job={job} />
                  </Box>
                  <Divider />
                </>
              ))}
            </VStack>
          </MenuList>
        </Menu>
      </Skeleton>
      <CampaignOnboardingJobErrorModal
        isOpen={isCampaignOnboardingJobErrorModalOpen}
        onClose={onCampaignOnboardingJobErrorModalClose}
        job={selectedJob}
      />
    </HStack>
  )
}

type JobDetailsCardProps = {
  job: CampaignOnboardingJobView
}

const JobDetailsCard = ({ job }: JobDetailsCardProps) => {
  const jobStatusIcon = () => {
    switch (job.status) {
      case 'pending':
      case 'processing':
        return <FaClock color="#FFC654" size={24} />
      case 'failed':
        return <FaCircleXmark color="#BF4141" size={24} />
      case 'success':
        return <FaCircleCheck color="#269166" size={24} />
    }
  }

  const jobTitle = () => {
    switch (job.type) {
      case 'upload_locations':
        return 'Upload Locations'
      case 'upload_allocations':
        return 'Upload Allocations'
      case 'upload_distributors':
        return 'Upload Distributors'
    }
  }

  const jobDescription = () => {
    switch (job.status) {
      case 'pending':
      case 'processing':
        return <Text textStyle="body-2"> We are processing your data.</Text>
      case 'failed':
        return (
          <VStack align="stretch" spacing={4} cursor="pointer">
            <Text textStyle="body-2">We were unable to process your data.</Text>
            <Text textStyle="body-2"> Click to view the errors. </Text>
          </VStack>
        )
      case 'success':
        return (
          <Text textStyle="body-2">
            We have successfully processed your data.
          </Text>
        )
    }
  }

  return (
    <HStack
      align="flex-start"
      width="408px"
      background="base.canvas.alt"
      paddingX={3}
      paddingY={4}
      spacing={3}
    >
      {jobStatusIcon()}
      <VStack alignItems="stretch" width="100%" spacing={1}>
        <HStack justifyContent="space-between">
          <Text textStyle="subhead-1" lineHeight="24px">
            {jobTitle()}
          </Text>
          <Text textStyle="caption-2" textAlign="end">
            {formatAsTimeFromNow(job.updatedAt)}
          </Text>
        </HStack>
        {jobDescription()}
      </VStack>
    </HStack>
  )
}
