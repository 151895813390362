/**
 * Do not edit directly
 * Generated on Wed, 26 Jul 2023 10:01:18 GMT
 */

export const colors = {
  purple: {
    '50': '#F5F5FC',
    '100': '#E6E6FA',
    '200': '#CBCBF2',
    '300': '#9B9BDE',
    '400': '#6F6FC7',
    '500': '#4F4FAB',
    '600': '#3A3A94',
    '700': '#2A2A7D',
    '800': '#1C1C69',
    '900': '#101052',
  },
  pink: {
    '50': '#FFF2F8',
    '100': '#FFE0EE',
    '200': '#FFB8D8',
    '300': '#FF82BA',
    '400': '#FF479A',
    '500': '#F5167A',
    '600': '#C91667',
    '700': '#A31555',
    '800': '#7D1544',
    '900': '#571030',
  },
  blue: {
    '50': '#F7F9FE',
    '100': '#E7EFFC',
    '200': '#ACC7FA',
    '300': '#82ABF7',
    '400': '#568DF4',
    '500': '#1361F0',
    '600': '#0D4FCA',
    '700': '#0B44AC',
    '800': '#093890',
    '900': '#072A69',
  },
  cobalt: {
    '50': '#EDF5FC',
    '100': '#DEECFA',
    '200': '#AACDF2',
    '300': '#73ADEB',
    '400': '#438EDE',
    '500': '#166BC7',
    '600': '#1159A6',
    '700': '#0D4682',
    '800': '#09315C',
    '900': '#061E38',
  },
  red: {
    '50': '#FCF5F5',
    '100': '#FAE3E3',
    '200': '#F2B6B6',
    '300': '#E88B8B',
    '400': '#D66060',
    '500': '#BF4141',
    '600': '#AB2929',
    '700': '#8F1F1F',
    '800': '#701818',
    '900': '#541212',
  },
  green: {
    '50': '#F0FAF3',
    '100': '#E1F7E9',
    '200': '#B7EBCC',
    '300': '#7CCFA1',
    '400': '#4AB080',
    '500': '#269166',
    '600': '#167852',
    '700': '#0F6143',
    '800': '#0A4A33',
    '900': '#063022',
  },
  yellow: {
    '50': '#FFFAEB',
    '100': '#FFF3D1',
    '200': '#FFDB80',
    '300': '#FFC654',
    '400': '#FFA526',
    '500': '#ED7700',
    '600': '#C75A00',
    '700': '#9E4200',
    '800': '#6B2700',
    '900': '#471500',
  },
  slate: {
    '50': '#f8f9fa',
    '100': '#e9eaee',
    '200': '#babecb',
    '300': '#9aa0b3',
    '400': '#7b849c',
    '500': '#5d6785',
    '600': '#465173',
    '700': '#3c4764',
    '800': '#333c56',
    '900': '#272d41',
  },
  teal: {
    '50': '#EDFCFB',
    '100': '#D2F7F4',
    '200': '#A6EDE6',
    '300': '#76DED3',
    '400': '#42C9BC',
    '500': '#1AAB9C',
    '600': '#128A7E',
    '700': '#0E695E',
    '800': '#0A4F48',
    '900': '#06302C',
  },
  grey: {
    '50': '#F8F9F9',
    '100': '#EDEDED',
    '200': '#BFC2C8',
    '300': '#A0A4AD',
    '400': '#838894',
    '500': '#666C7A',
    '600': '#505660',
    '700': '#454953',
    '800': '#3A3E46',
    '900': '#2C2E34',
  },
  standard: {
    white: '#ffffff',
    black: '#000000',
  },
  brand: {
    primary: {
      '50': '#F5F5FC',
      '100': '#E6E6FA',
      '200': '#CBCBF2',
      '300': '#9B9BDE',
      '400': '#6F6FC7',
      '500': '#4F4FAB',
      '600': '#3A3A94',
      '700': '#2A2A7D',
      '800': '#1C1C69',
      '900': '#101052',
    },
    secondary: {
      '50': '#f8f9fa',
      '100': '#e9eaee',
      '200': '#babecb',
      '300': '#9aa0b3',
      '400': '#7b849c',
      '500': '#5d6785',
      '600': '#465173',
      '700': '#3c4764',
      '800': '#333c56',
      '900': '#272d41',
    },
  },
  base: {
    canvas: {
      default: '#ffffff',
      alt: '#f8f9fa',
      backdrop: '#e9eaee',
      'brand-subtle': '#F5F5FC',
      inverse: '#333c56',
      overlay: 'rgba(51, 60, 86, 0.8)',
    },
    content: {
      default: '#3c4764',
      strong: '#272d41',
      medium: '#5d6785',
      brand: '#4F4FAB',
      inverse: '#ffffff',
    },
    divider: {
      subtle: '#f8f9fa',
      medium: '#e9eaee',
      strong: '#babecb',
      inverse: '#ffffff',
      brand: '#4F4FAB',
    },
  },
  interaction: {
    main: {
      default: '#4F4FAB',
      hover: '#3A3A94',
      active: '#2A2A7D',
    },
    'main-subtle': {
      default: '#E6E6FA',
      hover: '#CBCBF2',
      active: '#9B9BDE',
    },
    sub: {
      default: '#5d6785',
      hover: '#465173',
      active: '#3c4764',
    },
    'sub-subtle': {
      default: '#e9eaee',
      hover: '#babecb',
      active: '#9aa0b3',
    },
    critical: {
      default: '#BF4141',
      hover: '#AB2929',
      active: '#8F1F1F',
    },
    'critical-subtle': {
      default: '#FAE3E3',
      hover: '#F2B6B6',
      active: '#E88B8B',
    },
    warning: {
      default: '#ED7700',
      hover: '#C75A00',
      active: '#9E4200',
    },
    'warning-subtle': {
      default: '#FFF3D1',
      hover: '#FFDB80',
      active: '#FFC654',
    },
    success: {
      default: '#269166',
      hover: '#167852',
      active: '#0F6143',
    },
    'success-subtle': {
      default: '#E1F7E9',
      hover: '#B7EBCC',
      active: '#7CCFA1',
    },
    neutral: {
      default: '#5d6785',
      hover: '#465173',
      active: '#3c4764',
    },
    'neutral-subtle': {
      default: '#f8f9fa',
      hover: '#e9eaee',
      active: '#babecb',
    },
    muted: {
      main: {
        hover: '#F5F5FC',
        active: '#E6E6FA',
      },
      sub: {
        hover: '#f8f9fa',
        active: '#e9eaee',
      },
      critical: {
        hover: '#FCF5F5',
        active: '#FAE3E3',
      },
      neutral: {
        hover: '#f8f9fa',
        active: '#e9eaee',
      },
    },
    tinted: {
      main: {
        hover: 'rgba(79, 79, 171, 0.04)',
        active: 'rgba(79, 79, 171, 0.12)',
      },
      sub: {
        hover: 'rgba(93, 103, 133, 0.04)',
        active: 'rgba(93, 103, 133, 0.12)',
      },
      critical: {
        hover: 'rgba(191, 65, 65, 0.04)',
        active: 'rgba(191, 65, 65, 0.12)',
      },
      neutral: {
        hover: 'rgba(0, 0, 0, 0.1)',
        active: 'rgba(0, 0, 0, 0.2)',
      },
      inverse: {
        hover: 'rgba(255, 255, 255, 0.1)',
        active: 'rgba(255, 255, 255, 0.2)',
      },
    },
    support: {
      unselected: '#9aa0b3',
      selected: '#5d6785',
      'unselected-strong': '#5d6785',
      disabled: '#e9eaee',
      'disabled-content': '#9aa0b3',
      placeholder: '#7b849c',
    },
    links: {
      default: '#4F4FAB',
      hover: '#3A3A94',
      'neutral-default': '#3c4764',
      'neutral-hover': '#272d41',
      'inverse-default': '#ffffff',
      'inverse-hover': '#e9eaee',
    },
  },
  utility: {
    feedback: {
      info: '#1361F0',
      'info-subtle': '#F7F9FE',
      warning: '#ED7700',
      'warning-subtle': '#FFFAEB',
      success: '#269166',
      'success-subtle': '#F0FAF3',
      critical: '#BF4141',
      'critical-subtle': '#FCF5F5',
    },
    'focus-default': '#4F4FAB',
    'focus-inverse': '#ffffff',
    'input-prefilled': '#FFFAEB',
    ui: '#ffffff',
    'ui-clear': 'rgba(255, 255, 255, 0)',
  },
  skin: {
    '1base': '#FFCAA4',
    '1shadow': '#F5B896',
    '2base': '#EBA687',
    '2shadow': '#E29B7B',
    '3base': '#D98F6F',
    '3shadow': '#CE8361',
    '4base': '#BF7657',
    '4shadow': '#AF6D53',
    '5base': '#9F634F',
    '5shadow': '#8B5544',
    '6base': '#764738',
    '6shadow': '#633D32',
  },
}
