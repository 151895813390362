import { Box, Text } from '@chakra-ui/react'
import {
  createColumnHelper,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'

import { LocationView } from '~/types/location'

import DataTable, { ACTIONS_COLUMN_ID } from '~components/DataTable'

interface LocationsTableProps {
  data: LocationView[]
  enableRowEditOptions?: boolean
  UpdateRowModal?: React.ElementType<{
    isOpen: boolean
    onClose: () => void
    confirmUpdate: (data: unknown) => Promise<void> // TODO: not sure how to properly type while keeping it generic
    rowToUpdate: unknown
  }>
  handleUpdateRow?: (row: Row<LocationView>, data: unknown) => Promise<void>
  isUpdatingRow?: boolean
  handleDeleteRow?: (row: Row<LocationView>) => void
  isDeletingRow?: boolean
}

const columnHelper = createColumnHelper<LocationView>()

export const LocationsTable = ({
  data,
  enableRowEditOptions,
  UpdateRowModal,
  handleUpdateRow,
  isUpdatingRow,
  handleDeleteRow,
  isDeletingRow,
}: LocationsTableProps) => {
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: () => <Text textStyle="caption-1">{'name'.toUpperCase()}</Text>,
    }),
    columnHelper.accessor('shortCode', {
      cell: (info) => info.getValue(),
      header: () => (
        <Text textStyle="caption-1">{'location code'.toUpperCase()}</Text>
      ),
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => info.getValue(),
      header: () => <Text textStyle="caption-1">{'added'.toUpperCase()}</Text>,
    }),
    columnHelper.display({
      id: ACTIONS_COLUMN_ID,
      cell: () => null,
      header: () => (
        <Text textStyle="caption-1">{'actions'.toUpperCase()}</Text>
      ),
    }),
  ]

  const table = useReactTable({
    columns,
    data,
    // From docs (https://tanstack.com/table/v8/docs/api/features/pagination#pagecount), if row is not known `-1` can be set.
    pageCount: -1,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    enableSortingRemoval: false,
    manualSorting: true,
    manualPagination: true,
    enableColumnFilters: false,
    manualFiltering: true,
    enableSorting: false,
    initialState: {
      columnVisibility: {
        id: false,
        campaignId: false,
        updatedAt: false,
      },
    },
    state: {
      sorting: [],
    },
  })

  return (
    <Box>
      {data.length > 0 ? (
        <DataTable
          instance={table}
          borderColor="base.divider.medium"
          enableRowEditOptions={enableRowEditOptions}
          UpdateRowModal={UpdateRowModal}
          handleUpdateRow={handleUpdateRow}
          isUpdatingRow={isUpdatingRow}
          handleDeleteRow={handleDeleteRow}
          isDeletingRow={isDeletingRow}
        />
      ) : (
        <Text>There are no locations yet.</Text>
      )}
    </Box>
  )
}
