import { Center, HStack, Spinner, Switch, Text, VStack } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'
import _ from 'lodash'

import { useUpdateCampaign } from '../hooks/useUpdateCampaign'

import { StatusBadge } from '~components/Badge/StatusBadge'
import { useCampaign } from '~hooks/useCampaign'

// TODO: can extract into a generic toggle setting component later
export const ToggleCampaignStatus = ({
  campaignId,
}: {
  campaignId: string
}) => {
  const { campaign } = useCampaign(campaignId)
  const currCampaignStatus = campaign?.status

  const { updateCampaign } = useUpdateCampaign({
    campaignId,
  })

  const toast = useToast()

  return (
    <HStack
      w="full"
      alignItems="flex-start"
      justifyContent="space-between"
      gap={8}
    >
      <VStack alignItems="flex-start" gap={2} w="30rem">
        <Text textStyle="subhead-1">
          Campaign Status:{' '}
          <StatusBadge
            status={currCampaignStatus === 'active' ? 'success' : 'warning'}
            text={currCampaignStatus === 'active' ? 'Active' : 'Inactive'}
          />
        </Text>
        <Text textStyle="body-2">
          When activated, your distributors will be able to start distributing
          items.
        </Text>
        <Text textStyle="body-2">
          Once a distribution has been made, bulk uploading of locations,
          allocations, and distributors will be disabled.
        </Text>
      </VStack>
      {/* Height should match the lineHeight of the header text */}
      <Center height="1.5rem">
        {_.isUndefined(currCampaignStatus) ? (
          <Spinner />
        ) : (
          <Switch
            defaultChecked={currCampaignStatus === 'active'}
            onChange={async () => {
              // Don't do anything if campaign is not defined
              // This should not occur unless there is no campaign defined
              // for that campaignId.
              if (_.isUndefined(campaign)) {
                return
              }
              const updatedCampaignStatus =
                currCampaignStatus === 'active' ? 'inactive' : 'active'

              const campaignName = campaign.name
              const toastMessage =
                updatedCampaignStatus === 'active'
                  ? `You have successfully activated ${campaignName}.`
                  : `You have deactivated ${campaignName}.`
              try {
                await updateCampaign({
                  status: updatedCampaignStatus,
                })
                toast({
                  title: toastMessage,
                  status: 'success',
                })
              } catch (e) {
                toast({
                  title: 'Error updating campaign status',
                  description:
                    'Please try again later. If the problem persists, please contact the DistributeSG team.',
                  status: 'error',
                })
              }
            }}
          />
        )}
      </Center>
    </HStack>
  )
}
