import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsString,
  MaxLength,
} from 'class-validator'

import {
  CampaignIdentifierType,
  CampaignIdentifierTypes,
} from '~shared/constants/campaign'

// TODO: just setting a FE only arbitrary max lengths for campaign and product name
// since we don't enforce it on the BE
const CAMPAIGN_NAME_MAX_LENGTH = 30
const PRODUCT_NAME_MAX_LENGTH = 30

export class CreateCampaignFormState {
  @IsString()
  @MaxLength(CAMPAIGN_NAME_MAX_LENGTH, {
    message: `Campaign name must be less than ${CAMPAIGN_NAME_MAX_LENGTH} characters`,
  })
  @IsNotEmpty({
    message:
      'This field is required. Please enter your campaign name to continue.',
  })
  name: string

  @IsString()
  @IsEnum(CampaignIdentifierTypes)
  @IsNotEmpty({ message: 'Recipient type must be selected' })
  identifierType: CampaignIdentifierType

  @IsArray()
  @IsString({ each: true })
  @MaxLength(PRODUCT_NAME_MAX_LENGTH, {
    each: true,
    message: `Product names must be less than ${PRODUCT_NAME_MAX_LENGTH} characters`,
  })
  @IsNotEmpty({
    each: true,
    message:
      'Item names cannot be left empty. Please enter your items to continue.',
  })
  productNames: string[]

  @IsBoolean()
  hasQuantity: boolean

  @IsBoolean()
  isDoorToDoor: boolean

  // We use a string instead of a boolean for the radio button values,
  // as the radio button group manages the state based on the string
  // values of the radio buttons. By representing the "isWalkIn" value
  // as a string, we delegate the state management logic to the radio button
  // group.
  @IsString()
  @IsNotEmpty({
    message:
      'This field is required. Please indicate if you have a list of recipients.',
  })
  isWalkIn: string

  @IsString({ each: true })
  @IsNotEmpty({
    message:
      'This field is required. Please enter at least one location to continue.',
  })
  locationNames: string
}
