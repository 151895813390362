import { Button, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react'

import { RemoveCampaignModal } from './RemoveCampaignModal'

export const RemoveCampaign = ({
  campaignId,
}: {
  campaignId: string
}): JSX.Element => {
  // methods to handle opening and closing of
  // RemoveCampaignModal
  const {
    isOpen: isRemoveCampaignModalOpen,
    onOpen: onRemoveCampaignModalOpen,
    onClose: onRemoveCampaignModalClose,
  } = useDisclosure()

  return (
    <>
      <RemoveCampaignModal
        isOpen={isRemoveCampaignModalOpen}
        onClose={onRemoveCampaignModalClose}
        campaignId={campaignId}
      />
      <HStack w="full" alignItems="flex-start">
        <VStack alignItems="flex-start" mr="auto" w="30rem">
          <Text textStyle="subhead-1">Remove all access to campaign data</Text>
          <Text textStyle="body-2">
            Revoke all admin and distributor access. Before proceeding with the
            removal, ensure you have backed up all campaign data and exported
            your reports.
          </Text>
        </VStack>
        <Button
          variant="outline"
          onClick={onRemoveCampaignModalOpen}
          type="submit"
          size="xs"
          colorScheme="red"
        >
          Remove
        </Button>
      </HStack>
    </>
  )
}
