import { As, Box, Icon, Tab, Text } from '@chakra-ui/react'
import React from 'react'
export interface SettingsTabProps {
  label: string
  icon: As
  onClick: () => void
  isActiveTab: boolean
}

export const SideTab = ({
  label,
  icon,
  onClick,
  isActiveTab,
}: SettingsTabProps): JSX.Element => {
  return (
    <Tab
      justifyContent="flex-start"
      alignItems="center"
      py={2}
      pl={2}
      pr={4}
      margin="0 0 8px 0"
      onClick={onClick}
      backgroundColor={isActiveTab ? 'brand.primary.100' : 'none'}
      borderRadius="8px"
      w="100%"
      // The Tab component has a base text transform property that
      // capitalises the text. Hence, there is a need to override
      // it to be 'none'.
      textTransform="none"
    >
      <Icon
        as={icon}
        fontSize="1.25rem"
        color={
          isActiveTab
            ? 'interaction.main.default'
            : 'interaction.links.neutral-default'
        }
      />
      <Box ml={2}>
        <Text
          textStyle="subhead-2"
          color={
            isActiveTab
              ? 'interaction.main.default'
              : 'interaction.links.neutral-default'
          }
        >
          {label}
        </Text>
      </Box>
    </Tab>
  )
}
