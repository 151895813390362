import { isEmail, registerDecorator, ValidationOptions } from 'class-validator'

const ADMIN_WHITELISTED_DOMAINS = ['@np.edu.sg', '.gov.sg']
const ADMIN_WHITELISTED_EMAILS = [
  'samantha.choo@kidstart.sg',
  'szeern.tan@kidstart.sg',
  'jeanette.tan@kidstart.sg',
  'nasrullah.tajudeen@kidstart.sg',
  'norshima.aziz@kidstart.sg',
  'nasrullah.tajudeen@kidstart.sg',
  'razi.hamid@kidstart.sg',
  'anderson.sim@kidstart.sg',
  'farreha.jalil@kidstart.sg',
  'sarah.chin@kidstart.sg',
]

export const isAdminWhitelistedEmailDomain = (value: unknown) => {
  if (typeof value !== 'string' || !isEmail(value)) {
    return false
  }

  // Uncomment if environment is needed for whitelisting, NODE_ENV has to be last
  // since React defaults all builds to NODE_ENV=production
  // const env =
  //   process.env.ENV || process.env.REACT_APP_ENV || process.env.NODE_ENV

  // For whitelisting specific emails
  if (ADMIN_WHITELISTED_EMAILS.includes(value)) {
    return true
  }

  return ADMIN_WHITELISTED_DOMAINS.some((substring: string) => {
    return value.endsWith(substring)
  })
}

export const IsAdminWhitelistedEmailDomain = (options?: ValidationOptions) => {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name: 'isAdminWhitelistedEmailDomain',
      target: object.constructor,
      propertyName,
      options,
      validator: {
        validate: (value: unknown) => isAdminWhitelistedEmailDomain(value),
      },
    })
  }
}
