import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  SlideFade,
  Text,
  VStack,
} from '@chakra-ui/react'
import { BiCircle, BiPlus, BiSolidCheckCircle } from 'react-icons/bi'

import { CampaignIdentifierType } from '~shared/constants/campaign'

import IconHousehold from '~assets/preview/hi-outline-home.svg'
import IconUser from '~assets/preview/hi-outline-user.svg'
import LocationPreview from '~assets/preview/Location_Preview_PhoneUI.svg'
import Preview01NRIC from '~assets/preview/Preview01_NRIC.svg'
import Preview02Address from '~assets/preview/Preview02_Address_Default.svg'
import Preview03DistributeModalBottom from '~assets/preview/Preview03_DistributeModalBottom.png'
import Preview03DistributeModalMiddle from '~assets/preview/Preview03_DistributeModalMiddle.png'
import Preview03DistributeModalTop from '~assets/preview/Preview03_DistributeModalTop.png'
import Preview03DistributeModalMiddleNoEligibility from '~assets/preview/Preview03DistributeModalMiddleNoEligibility.svg'
import Preview04DoorToDoor from '~assets/preview/Preview04_DoorToDoor.svg'
import Preview05UniqueStringIdentifier from '~assets/preview/Preview05UniqueStringIdentifier.svg'
import CheckboxUnchecked from '~assets/preview/SelectCheckbox_unchecked.svg'

interface ExampleStateProps {
  basePreviewImageUrl: string
  recipientIconUrl: string
  exampleRecipient: string
}

export const getExampleStates = (
  beneficiaryType: CampaignIdentifierType,
  isDoorToDoor: boolean,
): ExampleStateProps => {
  switch (beneficiaryType) {
    case 'nric':
      return {
        basePreviewImageUrl: Preview01NRIC,
        recipientIconUrl: IconUser,
        exampleRecipient: 'S1234567A',
      }
    case 'address':
      return {
        basePreviewImageUrl: isDoorToDoor
          ? Preview04DoorToDoor
          : Preview02Address,
        recipientIconUrl: IconHousehold,
        exampleRecipient: 'Blk 321 Example Street #12-345 S654321',
      }
    case 'unique_string':
      return {
        basePreviewImageUrl: Preview05UniqueStringIdentifier,
        recipientIconUrl: IconUser,
        exampleRecipient: 'M12345X',
      }
  }
}

type AppPreviewProps = {
  shouldShowProductModal: boolean
  shouldShowLocationView: boolean
  beneficiaryType: CampaignIdentifierType
  isHasQuantityCampaign: boolean
  campaignName: string
  productNames: string[]
  locationNames: string[]
  setShouldShowProductModal: (shouldShowProductModal: boolean) => void
  isDoorToDoor: boolean
  isWalkIn: boolean
}

export const AppPreview = ({
  shouldShowProductModal,
  shouldShowLocationView,
  beneficiaryType,
  campaignName,
  productNames,
  locationNames,
  setShouldShowProductModal,
  isHasQuantityCampaign,
  isDoorToDoor,
  isWalkIn,
}: AppPreviewProps) => {
  const { basePreviewImageUrl, recipientIconUrl, exampleRecipient } =
    getExampleStates(beneficiaryType, isDoorToDoor)

  return (
    <VStack spacing={6} flexGrow={1} height="auto">
      <Box position="relative" overflow="hidden">
        <Box>
          {!shouldShowLocationView && (
            <Image src={basePreviewImageUrl} alt="Base app preview image" />
          )}
          {shouldShowLocationView && (
            <Image src={LocationPreview} alt="Base app preview image" />
          )}
        </Box>

        {/* distribution modal with products */}
        {shouldShowProductModal && (
          <SlideFade in={shouldShowProductModal}>
            <Box
              position="absolute"
              bottom={-2}
              zIndex={25}
              borderRadius="2xl"
              overflowY="visible"
            >
              {/* modal overlay */}
              <Box
                w="100%"
                h="100%"
                position="absolute"
                backgroundColor="rgba(0, 0, 0, 0.5)"
                bottom={245}
                zIndex={-1}
                borderRadius="2xl"
              ></Box>

              <Box zIndex={25}>
                <Image
                  src={Preview03DistributeModalTop}
                  alt="Image of distribute modal preview - top bar"
                  onClick={() => setShouldShowProductModal(false)}
                />
                <HStack backgroundColor="gray.100" px={3} pb={4} pt={1}>
                  <HStack alignItems="start">
                    <Flex
                      w="1.25rem"
                      h="1.25rem"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        w={4}
                        h={4}
                        src={recipientIconUrl}
                        alt="Recipient Icon"
                      />
                    </Flex>
                    <Text textStyle="subhead-2">{exampleRecipient}</Text>
                  </HStack>
                </HStack>
                <Image
                  src={
                    isWalkIn
                      ? Preview03DistributeModalMiddleNoEligibility
                      : Preview03DistributeModalMiddle
                  }
                  alt="Image of distribute modal preview - middle section"
                  bgColor="white"
                  w="100%"
                />
                <VStack
                  width="100%"
                  backgroundColor="white"
                  px={4}
                  spacing={2}
                  pt={2}
                  pb={2}
                >
                  {/* Only preview first 5 product names */}
                  {productNames.slice(0, 5).map((productName, idx) => (
                    <ListProduct
                      key={idx}
                      productName={productName}
                      hasQty={isHasQuantityCampaign}
                    />
                  ))}
                </VStack>
                <Image
                  src={Preview03DistributeModalBottom}
                  alt="Image of distribute modal preview - bottom section"
                  borderBottomRadius="2xl"
                  pb={2}
                />
              </Box>
            </Box>
          </SlideFade>
        )}
        {/* campaign name layer */}
        {!shouldShowLocationView && (
          <Flex
            position="absolute"
            top={12}
            zIndex={10}
            w="full"
            h="4rem"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxWidth="55%" flexWrap="wrap" textAlign="center">
              <Text color="white" textStyle="subhead-1">
                {campaignName}
              </Text>
            </Box>
          </Flex>
        )}

        {/* campaign name layer in location view*/}
        {shouldShowLocationView && (
          <Flex
            position="absolute"
            top="7.7rem"
            left={10}
            zIndex={10}
            w="full"
            h="4rem"
          >
            <VStack spacing={1} alignItems="flex-start">
              <Text textStyle="subhead-2" color="#282F3B" fontWeight="600">
                {campaignName}
              </Text>
            </VStack>
          </Flex>
        )}

        {/* location names in location view */}
        {shouldShowLocationView && (
          <VStack
            position="absolute"
            top="19rem"
            left={9}
            zIndex={20}
            spacing="1.5"
          >
            {/* Only preview first 5 location names */}
            {locationNames.slice(0, 5).map((locationName, idx) => (
              <ListLocatioName
                locationName={locationName}
                idx={idx}
                key={idx}
              />
            ))}
            <HStack
              borderRadius="3px"
              border="1px"
              w="100%"
              py={2}
              borderColor="#6F6FC7"
              borderStyle="dashed"
              alignContent="center"
              justifyContent="center"
            >
              <BiPlus color="#6F6FC7" />
              <Text color="brand.primary.500" textStyle="caption-1">
                Add Location
              </Text>
            </HStack>
          </VStack>
        )}
      </Box>
      <Text textStyle="body-2" color="base.content.medium" align="center">
        Preview of DistributeSG mobile app
      </Text>
    </VStack>
  )
}

type ListLocationNameProps = {
  locationName: string
  idx: number
}

const ListLocatioName = ({ locationName, idx }: ListLocationNameProps) => {
  const isFirstLocationName = idx === 0
  return (
    <Flex
      border="1px"
      borderRadius="3px"
      w="13rem"
      h="2rem"
      py={2}
      backgroundColor={isFirstLocationName ? 'green.50' : 'white'}
      borderColor={isFirstLocationName ? '#228A60' : 'brand.secondary.200'}
    >
      <HStack ml={1}>
        {isFirstLocationName && <BiSolidCheckCircle color="#42AD7B" />}
        {!isFirstLocationName && <BiCircle />}
        <Text textStyle="caption-1" fontWeight="500">
          {locationName}
        </Text>
      </HStack>
    </Flex>
  )
}

type ListProductProps = {
  productName: string
  hasQty: boolean
}

const ListProduct = ({ productName, hasQty }: ListProductProps) => {
  return (
    <Flex
      width="100%"
      borderRadius="md"
      borderWidth={hasQty ? '0px' : '1px'}
      borderColor="gray.200"
    >
      <HStack
        justifyContent="start"
        alignItems="center"
        h={12}
        px={3}
        width="100%"
      >
        {!hasQty && <Image src={CheckboxUnchecked} alt="Checkbox unchecked" />}
        <Text flexGrow={1} textStyle="subhead-2" flexWrap="wrap">
          {productName}
        </Text>
        {hasQty && (
          <HStack
            flexGrow={1}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
          >
            <IconButton
              isDisabled={true}
              icon={<MinusIcon fontSize="sm" />}
              size="xs"
              aria-label="Minus qty button for preview"
            />
            <Text textStyle="body-2"> 1 </Text>
            <IconButton
              isDisabled={true}
              icon={<AddIcon fontSize="sm" />}
              size="xs"
              aria-label="Minus qty button for preview"
            />
          </HStack>
        )}
      </HStack>
    </Flex>
  )
}
