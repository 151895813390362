import {
  VerifyOtpRequestDto,
  VerifyOtpResponseDto,
} from '~shared/types/auth.dto'

import { ApiService } from '~lib/helpers/api'

export const verifyLoginOtp = async (params: VerifyOtpRequestDto) => {
  return ApiService.post<VerifyOtpResponseDto>('/auth/verify', params)
}
