import { StreamParser } from '@json2csv/plainjs'
import { useToast } from '@opengovsg/design-system-react'
import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { useRef, useState } from 'react'

import { ListAllocationPaginatedResponseDto } from '~shared/types/allocation.dto'

import { CampaignView } from '~/types/campaign'

import {
  extractMetadataFields,
  getProductNameToQuantityMapping,
} from './useExportDistributions'

import { ApiService } from '~lib/helpers/api'
import { reprintDateTime } from '~utils/date'
import { exportCsv, ReportType } from '~utils/export'

enum DistributionStatus {
  DISTRIBUTED = 'distributed',
  NOT_DISTRIBUTED = 'not distributed',
}

const NUM_ROWS_PER_CALL = 10000 // Number of rows fetched per API call
const MAX_TRIES = 3 // Total 3 tries

type AllocationViewToExport = {
  id: string
  identifier?: string
  uniqueStringIdentifier?: string
  postalCode?: string
  block?: string
  floor?: string
  unit?: string
  allocatedProducts: Record<string, number>
  allocationMetadata: Record<string, unknown>
  locationName: string
  status: DistributionStatus
  createdAt: string
  updatedAt: string
}

export const useExportAllocations = (
  campaignId: string,
  campaign: CampaignView | undefined,
) => {
  const toast = useToast()
  const [exporting, setExporting] = useState(false)
  const [hasErrorExporting, setHasErrorExporting] = useState<boolean>(false)
  // due to multiple re-renders happening, use a reference to keep track of
  // the same instance of AbortController
  const abortController = useRef<AbortController>(new AbortController())
  const exportAllocations = async () => {
    if (!campaign) {
      throw new Error('Campaign is undefined')
    }

    setExporting(true)

    // Perform a recursive query on the paginated API to fetch
    // all allocations
    const allocationsToExport: AllocationViewToExport[] = []
    let hasNextPage
    let after: string | undefined

    // Also adding a retry mechanism in case one of calls fail
    let numTries = 0
    const allocationMetadataFields = new Set<string>()

    do {
      try {
        numTries += 1
        const response =
          await ApiService.get<ListAllocationPaginatedResponseDto>(
            `/campaigns/${campaignId}/allocations`,
            {
              params: { limit: NUM_ROWS_PER_CALL, after },
              signal: abortController.current.signal,
            },
          )
        const { data, pageInfo } = camelcaseKeys(response.data, { deep: true })

        data.forEach((allocation) => {
          extractMetadataFields(
            allocation?.metadata ?? {},
            allocationMetadataFields,
          )

          const allocationToExport: AllocationViewToExport = {
            id: allocation.id,
            ...(campaign.identifierType === 'nric' && {
              identifier: allocation.identifier || '',
            }),
            ...(campaign.identifierType === 'unique_string' && {
              uniqueStringIdentifier: allocation.uniqueStringIdentifier || '',
            }),
            ...(campaign.identifierType === 'address' && {
              postalCode: allocation.postalCode || '',
            }),
            ...(campaign.identifierType === 'address' && {
              block: allocation.block || '',
            }),
            ...(campaign.identifierType === 'address' && {
              floor: allocation.floor || '',
            }),
            ...(campaign.identifierType === 'address' && {
              unit: allocation.unit || '',
            }),
            allocatedProducts: getProductNameToQuantityMapping(
              campaign,
              allocation.products,
            ),
            allocationMetadata: allocation.metadata ?? {},
            locationName: allocation.locationName ?? '',
            status: allocation.hasDistributions
              ? DistributionStatus.DISTRIBUTED
              : DistributionStatus.NOT_DISTRIBUTED,
            createdAt: reprintDateTime(allocation.createdAt),
            updatedAt: reprintDateTime(allocation.updatedAt),
          }

          allocationsToExport.push(allocationToExport)
        })

        hasNextPage = pageInfo.hasNextPage
        after = pageInfo.endCursor ?? undefined

        // Reset the number of tries on success
        numTries = 0
      } catch (e) {
        // this checks if the error is caused by an abort
        // instead of an error sent from the server
        if (axios.isCancel(e)) {
          setExporting(false)
          // a single abortController instance can only abort once and it will
          // retain its abort signal even when .abort() is called multiple times.
          // Hence after every cancellation, update the ref to reference a new
          // AbortController instance
          abortController.current = new AbortController()
          return
        }

        if (numTries < MAX_TRIES) {
          hasNextPage = true
          continue
        }

        // Terminate the export report process
        setExporting(false)
        setHasErrorExporting(true)
        return
      }
    } while (hasNextPage)

    if (allocationsToExport.length === 0) {
      toast({
        status: 'warning',
        description: 'There are no allocations to export.',
      })
      setExporting(false)
      setHasErrorExporting(false)
      return
    }

    const opts = {
      fields: generateOrderedColumns(
        campaign,
        Array.from(allocationMetadataFields),
      ),
    }
    const asyncOpts = { objectMode: true }
    const parser = new StreamParser(opts, asyncOpts)
    let csv = ''
    parser.onData = (chunk) => (csv += chunk.toString())
    parser.onEnd = () => {
      const blob = new Blob([csv], { type: 'text/csv' })

      exportCsv(ReportType.Allocation, blob, campaign.name)
      setExporting(false)
      setHasErrorExporting(false)
    }
    parser.onError = (err) => console.error(err)
    allocationsToExport.forEach((record) => parser.pushLine(record))

    parser.end()
    abortController.current = new AbortController()
  }
  return {
    exporting,
    exportAllocations,
    abortController,
    hasErrorExporting,
  }
}

function generateOrderedColumns(
  campaign: CampaignView,
  allocationMetadataFields: string[],
) {
  // Conditionally including identifier columns this way because spreading
  // in the final object causes some type errors in the StreamParser opts
  const identifierColumns = []
  switch (campaign.identifierType) {
    case 'nric':
      identifierColumns.push({
        label: 'Identifier',
        value: 'identifier',
      })
      break
    case 'unique_string':
      identifierColumns.push({
        label: 'Unique Identifier',
        value: 'uniqueStringIdentifier',
      })
      break
    case 'address':
      identifierColumns.push(
        {
          label: 'Postal Code',
          value: 'postalCode',
        },
        {
          label: 'Block',
          value: 'block',
        },
        {
          label: 'Floor',
          value: 'floor',
        },
        {
          label: 'Unit',
          value: 'unit',
        },
      )
  }

  return [
    {
      label: 'ID',
      value: 'id',
    },
    ...identifierColumns,
    ...campaign.products.map((product) => {
      return {
        label: `Allocated Qty - ${product.name}`,
        value: (record: AllocationViewToExport) => {
          const qty = record.allocatedProducts[product.id]
          if (qty === undefined || qty === 0) {
            return '-'
          }
          return qty
        },
      }
    }),
    ...allocationMetadataFields.map((field) => {
      return {
        label: `Allocation Metadata - ${field}`,
        value: (record: AllocationViewToExport) => {
          return record.allocationMetadata[field] ?? ''
        },
      }
    }),
    {
      label: 'Location Name',
      value: 'locationName',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Created At',
      value: 'createdAt',
    },
    {
      label: 'Updated At',
      value: 'updatedAt',
    },
  ]
}
