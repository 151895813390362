import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { Box } from '@chakra-ui/react'
import { ThemeProvider } from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRef } from 'react'

import { theme } from '~/theme'

import { AppRouter } from './AppRouter'

import { AuthProvider } from '~lib/auth'
import { initMonitoringIfTokensPresent } from '~lib/helpers/monitoring'

export const queryClient = new QueryClient()

// init dd rum monitoring here
initMonitoringIfTokensPresent()

export const App = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider
        theme={theme}
        resetCSS
        toastOptions={{ portalProps: { containerRef: ref } }}
      >
        <AuthProvider>
          <AppRouter />
          {/*
            Allow toast to be in front of modal. Solution based on https://github.com/chakra-ui/chakra-ui/issues/6276 
            TODO: Remove this when the issue is fixed
          */}
          <Box ref={ref} />
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
