import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedProperties } from 'type-fest'

import {
  DistributionListingResponseDto,
  ListDistributionPaginatedResponseDto,
  ListDistributionQueryDto,
} from '~shared/types/distribution.dto'

import { usePaginatedQuery } from '~hooks/usePaginatedQuery'
import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'
import { reprintDateTime } from '~utils/date'

export type DistributionSearchParam = CamelCasedProperties<
  Pick<
    ListDistributionQueryDto,
    | 'identifier'
    | 'address'
    | 'distributor_identifier'
    | 'location_name'
    | 'unique_string_identifier'
  >
>

export const useDistributions = (campaignId: string, limit: number) => {
  const {
    entries,
    allEntries,
    isEntriesLoading,
    isFetchingEntries,
    isFetchingEntriesNextPage,
    isFetchingEntriesPreviousPage,
    getNextPageOfEntries,
    getPreviousPageOfEntries,
    refetchEntries,
    fetchEntriesError,
    fetchEntriesCurrentSearchQuery,
    refreshFetchEntries,
    updateFetchEntriesSearchQuery,
  } = usePaginatedQuery<
    DistributionListingResponseDto,
    DistributionSearchParam
  >({
    apiFunction: async (apiParams) => {
      const queryParams: ListDistributionQueryDto = {
        limit: apiParams.limit,
        after: apiParams.after,
        before: apiParams.before,
        ...(apiParams.search && snakecaseKeys(apiParams.search)),
      }

      const response =
        await ApiService.get<ListDistributionPaginatedResponseDto>(
          `/campaigns/${campaignId}/distributions`,
          {
            params: queryParams,
          },
        )

      return {
        data: camelcaseKeys(response.data.data),
        pageInfo: camelcaseKeys(response.data.page_info),
      }
    },
    limit,
    refetchInterval: 10000, // 10s
    ...queryKeys.distributions.list,
  })

  return {
    distributions: (entries ?? []).map((distribution) => {
      return {
        ...distribution,
        updatedAt: reprintDateTime(distribution.updatedAt),
      }
    }),
    allDistributions: allEntries,
    isDistributionsLoading: isEntriesLoading,
    fetchDistributions: refetchEntries,
    isFetchingDistributions: isFetchingEntries,
    getNextPageOfDistributions: getNextPageOfEntries,
    getPreviousPageOfDistributions: getPreviousPageOfEntries,
    isFetchingDistributionsNextPage: isFetchingEntriesNextPage,
    isFetchingDistributionsPreviousPage: isFetchingEntriesPreviousPage,
    fetchDistributionsError: fetchEntriesError,
    fetchDistributionsCurrentSearchQuery: fetchEntriesCurrentSearchQuery,
    refreshFetchDistributions: refreshFetchEntries,
    updateFetchDistributionsSearchQuery: updateFetchEntriesSearchQuery,
  }
}
