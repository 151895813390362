import camelcaseKeys from 'camelcase-keys'

import {
  BaseLocationResponseDto,
  ListLocationPaginatedResponseDto,
} from '~shared/types/location.dto'

import { usePaginatedQuery } from '~hooks/usePaginatedQuery'
import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'
import { reprintDateTime } from '~utils/date'

export const useLocations = (campaignId: string, limit: number) => {
  const {
    entries,
    allEntries,
    isEntriesLoading,
    isFetchingEntries,
    isFetchingEntriesNextPage,
    isFetchingEntriesPreviousPage,
    getNextPageOfEntries,
    getPreviousPageOfEntries,
    refetchEntries,
    fetchEntriesError,
    fetchEntriesCurrentSearchQuery,
    refreshFetchEntries,
    updateFetchEntriesSearchQuery,
  } = usePaginatedQuery<BaseLocationResponseDto>({
    apiFunction: async (params) => {
      const response = await ApiService.get<ListLocationPaginatedResponseDto>(
        `/campaigns/${campaignId}/locations`,
        {
          params,
        },
      )

      return {
        // Specifying deep true otherwise types of nested objects don't resolve properly
        data: camelcaseKeys(response.data.data, { deep: true }),
        pageInfo: camelcaseKeys(response.data.page_info),
      }
    },
    limit,
    // API call is lightweight and we want to ensure data is consistent
    // between users viewing the locations data within the same campaign
    refetchInterval: 5000, // 5 seconds
    ...queryKeys.campaigns.detail(campaignId)._ctx.locations,
  })

  return {
    locations: (entries ?? []).map((location) => {
      return {
        ...location,
        createdAt: reprintDateTime(location.createdAt),
      }
    }),
    allLocations: allEntries,
    isLocationsLoading: isEntriesLoading,
    fetchLocations: refetchEntries,
    isFetchingLocations: isFetchingEntries,
    getNextPageOfLocations: getNextPageOfEntries,
    getPreviousPageOfLocations: getPreviousPageOfEntries,
    isFetchingLocationsNextPage: isFetchingEntriesNextPage,
    isFetchingLocationsPreviousPage: isFetchingEntriesPreviousPage,
    fetchLocationsError: fetchEntriesError,
    fetchLocationsCurrentSearchQuery: fetchEntriesCurrentSearchQuery,
    refreshFetchLocations: refreshFetchEntries,
    updateFetchLocationsSearchQuery: updateFetchEntriesSearchQuery,
  }
}
