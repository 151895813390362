import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Singapore')

const CURRENT_DATE_TIME_FORMAT = 'DD / MM / YYYY, h:mm A'

export const reprintDateOnly: (date: string) => string = (date: string) => {
  return new Date(date).toLocaleString('en-SG', {
    dateStyle: 'medium',
    timeZone: 'Asia/Singapore',
  })
}

// TODO: Change this implementation use moment as well
export const reprintDateTime: (date: string) => string = (date: string) => {
  const dateObject = new Date(date)
  const minutesFromUTCSigned = dateObject.getTimezoneOffset()
  const dateObjectWithTimeOffset = new Date(
    dateObject.getTime() - minutesFromUTCSigned * 60 * 1000,
  )
  const dateString = dateObjectWithTimeOffset.toISOString().split('T')[0]
  const timeString = dateObject.toLocaleString('en-SG', {
    timeStyle: 'short',
    timeZone: 'Asia/Singapore',
  })
  return `${dateString} ${timeString}`
}

export const getTimestampForCsvReport: () => string = () => {
  const currentMoment = moment()
  const dateString = currentMoment.format('YYYY_MM_DD')
  const timeString = currentMoment.format('HH_mm_ss')

  return `${dateString}_${timeString}`
}

export const getCurrentTime: () => string = () => {
  const currentMoment = moment()
  const currentDateTime = currentMoment.format(CURRENT_DATE_TIME_FORMAT)

  return currentDateTime
}
