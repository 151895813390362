import _ from 'lodash'

export function formatAsAddressString({
  postalCode,
  floor,
  unit,
  streetName,
}: {
  postalCode?: string | null
  floor?: string | null
  unit?: string | null
  streetName?: string | null
}) {
  const addressArray: Array<string> = []

  // Leave out block in the formatting because it's already included in streetName

  if (streetName) {
    addressArray.push(streetName)
  }

  if (floor && unit) {
    addressArray.push(`#${floor}-${unit}`)
  }

  if (postalCode) {
    addressArray.push(`S${postalCode}`)
  }

  return _.compact(addressArray).join(' ')
}
