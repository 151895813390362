import { IsNotEmpty, IsString } from 'class-validator'

import { IsAdminWhitelistedEmailDomain } from '~shared/decorators/is-admin-whitelisted-email-domain'

export class ManageCampaignAdminFormState {
  @IsString()
  @IsAdminWhitelistedEmailDomain({
    message: 'Only emails with whitelisted domains may be added as admins.',
  })
  @IsNotEmpty({ message: 'Email must not be empty' })
  email: string
}
