import { datadogRum } from '@datadog/browser-rum'
import { JsonObject } from 'type-fest'

// function to initiliase datadog rum
export function initMonitoringIfTokensPresent() {
  if (
    !process.env.REACT_APP_DATADOG_APPLICATION_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    return
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'distributesg-admin',
    env: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    // Sample rate in % (i.e 100 refers to 100% of all sessions recorded)
    // Considering we do not have many users (admin < 1000s), we can have
    // a 100% sample rate to gather more information.
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    silentMultipleInit: true,

    // Integrate with traces
    allowedTracingUrls: [/https:\/\/.*(\.)?distribute\.gov\.sg/],
  })
}

function ddAddCustomAction({
  actionName,
  context,
}: {
  actionName: string
  context?: JsonObject
}) {
  datadogRum.addAction(actionName, context)
}

// function to add custom Datadog user actions to track
export function addCustomMonitorClick(targetName: string, userId: string) {
  ddAddCustomAction({
    actionName: targetName,
    context: {
      target: {
        name: targetName,
      },
      userId: {
        id: userId,
      },
    },
  })
}
