import { Button, HStack, Text, VStack } from '@chakra-ui/react'

import { EmptyAllocationsCardSvg } from './EmptyAllocationsCardSvg'

import { EmptyStateCard } from '~components/EmptyStateCard/EmptyStateCard'

// content to be placed as children of EmptyPageCard
// for when there is no Allocation data
export const EmptyAllocationsCard = ({
  handleOnUploadMultipleAllocationsButtonClick,
}: {
  handleOnUploadMultipleAllocationsButtonClick: () => void
}): JSX.Element => {
  return (
    <EmptyStateCard>
      <HStack justifyContent="space-evenly" pl={12} pr={35}>
        {/* left content */}
        <VStack maxW="25rem" alignItems="flex-start" spacing={8}>
          <Text textStyle="body-1">
            This is where you decide who receives the items that are being
            distributed, and where they should collect the items from.
          </Text>
          <HStack>
            <Button
              px={4}
              py={2}
              onClick={handleOnUploadMultipleAllocationsButtonClick}
            >
              <Text textStyle="subhead-2">Upload Multiple Allocations</Text>
            </Button>
          </HStack>
        </VStack>

        {/* right content */}
        <EmptyAllocationsCardSvg maxW="100%" aria-hidden />
      </HStack>
    </EmptyStateCard>
  )
}
