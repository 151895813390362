import { useQuery } from '@tanstack/react-query'

import { StatisticsDto } from '~shared/types/statistics.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export const useCampaignStatistics = (campaignId: string) => {
  const getStatistics = async () => {
    const response = await ApiService.get<StatisticsDto>(
      `/campaigns/${campaignId}/statistics`,
    )

    return response.data
  }

  const {
    data: campaignStatistics,
    isLoading,
    isFetching,
    refetch: refetchCampaignStatistics,
  } = useQuery({
    ...queryKeys.campaigns.detail(campaignId)._ctx.statistics,
    queryFn: getStatistics,
    // Do not refetch data if user leaves application and returns, even if
    // query data is stale.
    refetchOnWindowFocus: false,

    // Stale time of 10 minutes because we don't want to refetch so often
    // as the query fetches a lot of data.
    staleTime: 600000,
  })

  return {
    campaignStatistics,
    refetchCampaignStatistics,
    isLoading,
    isFetching,
  }
}
