import { SpaceProps } from '@chakra-ui/react'
import { Badge } from '@opengovsg/design-system-react'

type PlainBadgeProps = {
  label: string
} & SpaceProps

export const PlainBadge = ({ label, ...spaceProps }: PlainBadgeProps) => {
  return (
    <Badge
      size="sm"
      variant="subtle"
      key={label}
      {...spaceProps}
      bg="slate.100"
      color="slate.600"
      py={1}
      px={2}
      borderRadius="4px"
      textStyle="caption-1"
    >
      {label}
    </Badge>
  )
}
