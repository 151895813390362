import { Skeleton, TabList, Tabs, Text, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import { BiBarChartSquare } from 'react-icons/bi'
import {
  HiOutlineCog,
  HiOutlineInbox,
  HiOutlineLocationMarker,
  HiOutlineTicket,
  HiOutlineUser,
} from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import { SideTab } from './SideTab'

import { useCampaign } from '~hooks/useCampaign'

interface SideTabListProps {
  campaignId: string
  activeIndex?: number
}

export const SideTabList = ({
  campaignId,
  activeIndex,
}: SideTabListProps): JSX.Element => {
  const navigate = useNavigate()

  const { campaign, isCampaignLoading } = useCampaign(campaignId)

  const isWalkIn = !_.isUndefined(campaign) && campaign.isWalkIn

  return (
    // While campaign information is being fetched there will be a split second,
    // where the Allocations tab will be shown and then disappear once the campaign
    // is fetched. Hence we use a skeleton to blur out the tabs until campaign data
    // has finished fetching to prevent the sudden shift in side menu tabs.
    <Skeleton
      isLoaded={!isCampaignLoading}
      startColor="grey.100"
      endColor="grey.50"
    >
      <Tabs
        orientation="vertical"
        variant="solid-rounded"
        isManual
        index={activeIndex}
      >
        <VStack alignItems="flex-start">
          <Text textStyle="caption-2" pl={2}>
            CAMPAIGN SETUP
          </Text>
          <TabList alignItems="flex-start" display="inline-flex" width="100%">
            <SideTab
              onClick={() => navigate(`/${campaignId}/locations`)}
              icon={HiOutlineLocationMarker}
              label="Locations"
              isActiveTab={activeIndex === 0}
            />
            {!isWalkIn && (
              <SideTab
                onClick={() => navigate(`/${campaignId}/allocations`)}
                icon={HiOutlineInbox}
                label="Allocations"
                isActiveTab={activeIndex === 1}
              />
            )}
            <SideTab
              onClick={() => navigate(`/${campaignId}/distributors`)}
              icon={HiOutlineUser}
              label="Distributors"
              isActiveTab={activeIndex === 2}
            />
            <SideTab
              onClick={() => navigate(`/${campaignId}/settings`)}
              icon={HiOutlineCog}
              label="Settings"
              isActiveTab={activeIndex === 3}
            />
          </TabList>
          <Text textStyle="caption-2" pl={2}>
            CAMPAIGN INFORMATION
          </Text>
          <TabList width="100%">
            <SideTab
              onClick={() => navigate(`/${campaignId}/distributions`)}
              icon={HiOutlineTicket}
              label="Distributions"
              isActiveTab={activeIndex === 4}
            />
            <SideTab
              onClick={() => navigate(`/${campaignId}/statistics`)}
              icon={BiBarChartSquare}
              label="Statistics"
              isActiveTab={activeIndex === 5}
            />
          </TabList>
        </VStack>
      </Tabs>
    </Skeleton>
  )
}
